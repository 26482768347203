import React from "react";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faAngleDoubleRight,
    faCheck,
    faEdit,
    faFileInvoice,
    faInfo,
    faQuestionCircle,
    faRemove,
    faSearch,
    faSpinner,
    faTimes,
    faTrash,
} from "@fortawesome/free-solid-svg-icons";
// import unitsOfMeasure from "../../../services/unitsOfMeasure.json";
import { useAppContext } from "../../../AppContext";
import { useTranslation } from "react-i18next";
import func from "../../../services/functions";
import cities from "../../../services/cities.json";
import mainAxios from "../../../services/mainAxios";
import { toast } from "react-toastify";
import Loader from "../../Layout/Loader";
import { useState } from "react";
import DemoWTNtable from "./DemoWTNtable";
import AlertCertificate from "../../global/AlertCertificate";
import PopOver from "../../global/PopOver";
import diacritics from 'diacritics';





const getExpandButtons = () =>
    document.querySelectorAll(
        "[aria-label='Expand Row'], [aria-label='Collapse Row']"
    );
    
let itemCount = 0;


const DemoWtn = ({ wtnState, setWtnState, canEdit, pageState, handleSave, errors, buttons, unitsOfMeasure,isEdit, ...props }) => {
    const issueDateRef = React.useRef();

    const customStyles = {
        option: (styles, state) => ({
            ...styles,
            color: state.isSelected ? "#000" : styles.color,
            backgroundColor: state.isSelected ? "#0075670d" : styles.color,
            borderBottom: "0.3px solid rgba(40, 167, 69, 0.25)",
            "&:hover": {
                color: "#000000",
                backgroundColor: "#0075670d",
            },
        }),

        control: (styles, state) => ({
            ...styles,
            boxShadow: state.isFocused ? "0 0 0 0.16rem rgba(68, 68, 68, 0.135)" : 0,
            borderWidth: state.isFocused ? "1px" : "1px",
            borderColor: state.isFocused ? "#28f0d8" : "#CED4DA",
            // border: errors?.["parties.transporter.name"] ? "solid red 1px" : "",
            minHeight: "14px",
            maxHeight: "34px",
            fontSize: "0.8rem",
            "&:hover": {
                borderColor: state.isFocused ? "#28f0d8" : "#CED4DA",
            },
        }),
    };
    const { t, i18n } = useTranslation("translations");


    const popOver = {
        NOINTERNET: t("hints.noInternet"),
        BOUNDBOOK: t("hints.boundBook"),
        SERVICE: t("hints.service"),
        TECHNICALERROR: t("hints.TechnicalError"),
    };

    const {
        user: [user],
        loadingVersion: loadingVersion,
        showVersion: showVersion,
        handleShowVersion,
        selectedCompany: [selectedCompany],
        company: [fullCompany]
    } = useAppContext();
    const company = user.companiesRights.find(
        (company) => company.id === parseInt(selectedCompany)
    );
    const [showBanner, setShowBanner] = useState(true);
    const [inputClass, setInputClass] = React.useState("");
    const [minIssueDate, setMinIssueDate] = React.useState("");


    React.useEffect(() => {
        if (wtnState?.items.length > itemCount && getExpandButtons().length > 0) {
            const buttons = getExpandButtons();
            buttons[buttons.length - 1].click();
        }
        itemCount = wtnState?.items?.length;
    }, [wtnState?.items?.length]);

    const CustomOption = ({ innerProps, isDisabled, label, data }) =>
        !isDisabled ? (
            <div className="option-custom-style" {...innerProps}>
                {/* {label} <PopOver title={popoOver.find(popover=>popover.value==data.value).popoOver} /> */}
                {label} <PopOver title={popOver[data.value]} />
                {innerProps.label}
            </div>
        ) : null;

        const handleSubseqChange = (subseq) => {
            const { value, label } = subseq;
            setWtnState((wtnState) => ({
              ...wtnState,
              subseq: value,
            }));
            var newDate = new Date();
            
            if (value === "BOUNDBOOK") {
              newDate = func.dateBeforeSomeDays(11, newDate);
            } else if (value === "SERVICE" || value === "TECHNICALERROR") {
              newDate = func.dateBeforeSomeDays(1, newDate);
            } else {
              newDate = func.getMinIssueDate();
            }
            const { issueDate } = wtnState;
        
            setMinIssueDate(newDate);
        
            if (
              (value === "BOUNDBOOK" ||
                value === "SERVICE" ||
                value === "TECHNICALERROR") &&
              issueDate < newDate
            ) {
              setWtnState((wtnState) => ({
                ...wtnState,
                issueDate: new Date(),
              }));
              issueDateRef.current.setOpen(true);
            }
          };
        
    const handleKeyDown = (e) => {
        if (e.key === " ") {
            e.preventDefault();
        }
    };
    const handleInputChange = (value, key) => {
        const keys = key.split(".");
        switch (keys.length) {
            case 1:
                setWtnState((wtnState) => ({
                    ...wtnState,
                    [keys[0]]: value,
                }));
                break;
            case 2:
                setWtnState((wtnState) => ({
                    ...wtnState,
                    [keys[0]]: {
                        ...wtnState[keys[0]],
                        [keys[1]]: value,
                    },
                }));
                break;
            case 3:
                setWtnState((wtnState) => ({
                    ...wtnState,
                    [keys[0]]: {
                        ...wtnState[keys[0]],
                        [keys[1]]: {
                            ...wtnState[keys[0]][keys[1]],
                            [keys[2]]: value,
                        },
                    },
                }));
                break;
            default:
                return;
        }
    };

    const handleIssueDateChange = (date) => {
        setWtnState((wtnState) => ({
          ...wtnState,
          issueDate: date,
        }));
        issueDateRef.current.setOpen(false);
      
        let today = new Date();
        let technicalErrorMinDate = func.dateBeforeSomeDays(1, today);
        let boundBookMinDate = func.dateBeforeSomeDays(10, today);
      
        if (date == "" || date == null) return;
        today = today.setHours(0, 0, 0, 0);
        // date = date.setHours(0, 0, 0, 0);
        technicalErrorMinDate = technicalErrorMinDate.setHours(0, 0, 0, 0);
        boundBookMinDate = boundBookMinDate.setHours(0, 0, 0, 0);
        if (date >= today) {
          setWtnState((wtnState) => ({
            ...wtnState,
            subseq: "",
          }));
          return;
        }
        if (date >= technicalErrorMinDate && date < today) {
          setWtnState((wtnState) => ({
            ...wtnState,
            subseq: "SERVICE",
          }));
        } else if (date >= boundBookMinDate && boundBookMinDate < today) {
          setWtnState((wtnState) => ({
            ...wtnState,
            subseq: "BOUNDBOOK",
          }));
        } else {
          setWtnState((wtnState) => ({
            ...wtnState,
            subseq: "NOINTERNET",
          }));
        }
      };
      
    const handleTaxPayerChange = (e) => {
        let value = e.target.value;

        setWtnState((wtnState) => ({
            ...wtnState,
            parties: {
                ...wtnState?.parties,
                transporter: {
                    ...wtnState?.parties?.transporter,
                    idNum: value?value.trim():"",
                },
            },
        }));

        if (inputClass.length > 0) setInputClass("");
    };

    const handleTaxPayerSearch = () => {
        let transporterNIPT = wtnState?.parties?.transporter?.idNum
            .split(" ")
            .join("").trim();
        if (canEdit) {
            if (new RegExp("[a-zA-Z]{1}[0-9]{8}[a-zA-Z]{1}").test(transporterNIPT)) {
                    setInputClass("loading");
                    mainAxios
                        .post("apiEndpoint/search", {
                            object: "GetTaxpayersRequest",
                            value: transporterNIPT,
                        })
                        .then((res) => {
                            if (res?.data?.status === true) {
                                if (res.data.content.length === 0) {
                                    toast.warning(t(["toast.noTaxPayer"]), {
                                        containerId: "all_toast",
                                    });
                                    setInputClass("error");
                                } else {
                                    const taxPayer = res.data.content[0];

                                    setWtnState((wtnState) => ({
                                        ...wtnState,
                                        parties: {
                                            ...wtnState?.parties,
                                            transporter: {
                                                ...wtnState?.parties?.transporter,
                                                name: taxPayer?.name ? taxPayer?.name?.slice(0, 99) : null,
                                                town: taxPayer?.town ? taxPayer?.town.slice(0, 99) : null,
                                                address: taxPayer?.address
                                                    ? taxPayer?.address.slice(0, 200)
                                                    : null,
                                            },
                                        },
                                    }));
                                    setInputClass("success");
                                }
                            } else {
                                setInputClass("error");
                            }
                        })
                        .catch((e) => {
                            setInputClass("error");
                        });
            } else {
                toast.error(t(["toast.incorrectNipt"]), { containerId: "all_toast" });
                setInputClass("error");
            }
        }
    };


    const handleAddressChange = (index, value, key,fixedHours) => {
        let updatedValue = value;

        if (fixedHours && value) {
            updatedValue = new Date(value.getTime());
    
            const currentTime = new Date();
            updatedValue.setHours(currentTime.getHours(), currentTime.getMinutes(), 0, 0);
        }
        setWtnState((wtnState) => ({
            ...wtnState,
            address: [
                ...wtnState.address.slice(0, index),
                {
                    ...wtnState.address[index],
                    [key]: updatedValue,
                },
                ...wtnState.address.slice(index + 1),
            ],
        }));
    };

    const handleBranchChange = (branch) => {
        const tcrCodes = branch.tcrTypes
            .map((tcrType) => tcrType.tcrCode)
            .filter((tcrCode) => !!tcrCode);
        setWtnState((wtnState) => ({
            ...wtnState,
            businessUnitCode: branch.businUnitCode,
            tcrCode: tcrCodes[0] || "",
        }));
    };


    const deleteItem = (itemId) => {
        const index = wtnState.items.findIndex((item) => item.id === itemId);
        setWtnState((wtnState) => ({
            ...wtnState,
            items: [
                ...wtnState.items.slice(0, index),
                ...wtnState.items.slice(index + 1),
            ],
        }));
    };

    const typeOptions = [
        {
            label: t("invoice.wtn"),
            value: "WTN",
        },
        {
            label: t("invoice.sale"),
            value: "SALE",
        },
    ];

    const transactionOptions = [
        { label: t("invoice.transferWtn"), value: "TRANSFER" },
        { label: t("invoice.doorWtn"), value: "DOOR" },
        { label: t("invoice.salesWtn"), value: "SALES" },
        { label: t("invoice.examinationWtn"), value: "EXAMINATION" },

    ];

    // This line is needed if we want to remove branches that don't have TCR
    // const branchesOptions = company && company.branches.filter(branch => branch.tcrTypes.filter(tcrType => !!tcrType.tcrCode).length > 0).map((data) => ({
    const branchesOptions =
        company &&
        company.branches.map((data) => ({
            ...data,
            label: data.businUnitCode + " - " + data.sellerAddress,
            value: data.businUnitCode,
        }));

    const tcrOptions =
        company &&
        [
            ...new Set(
                company.branches
                    .reduce((acc, next) => acc.concat(next.tcrTypes), [])
                    .filter((tcrType) => !!tcrType.tcrCode)
                    .map((tcr) => tcr.tcrCode)
            ),
        ].map((tcrCode) => ({
            label: tcrCode,
            value: tcrCode,
        }));

    const packTypes = [
        { value: "Boxes", label: "Boxes" },
        { value: "Pallets", label: "Pallets" },
        { value: "Containers", label: "Containers" },
        { value: "Parcels", label: "Parcels" },
        { value: "Dunnage", label: "Dunnage" },
        { value: "Other", label: "Other" },
    ];

    const ownershipOptions = [
        { value: "OWNER", label: "Owner" },
        { value: "THIRDPARTY", label: "Third Party" },
    ];

    const idTypes = [
        { value: "NUIS", label: "NUIS Number" },
        { value: "ID", label: "Personal ID Number" },
    ];

    const destinationPoints = [
        { value: "WAREHOUSE", label: "Warehouse" },
        { value: "EXHIBITION", label: "Exhibition" },
        { value: "STORE", label: "Store" },
        { value: "SALE", label: "Point of Sale" },
        { value: "OTHER", label: "Other" },
    ];

    const addressPoints = [
        { value: "WAREHOUSE", label: "Warehouse" },
        { value: "EXHIBITION", label: "Exhibition" },
        { value: "STORE", label: "Store" },
        { value: "SALE", label: "Point of Sale" },
        { value: "ANOTHER", label: "Another Person's warehouse" },
        { value: "CUSTOMS", label: "Customs Warehouse" },
        { value: "OTHER", label: "Other" },
    ];

    const subseqValues = [
        { value: "", label: "Select" },
        { value: "NOINTERNET", label: "No Internet" },
        { value: "BOUNDBOOK", label: "Boundbook" },
        { value: "SERVICE", label: "Service" },
        { value: "TECHNICALERROR", label: "Technical Error" },
    ];

    return (
        <>
            <div className="demoInvoice">
                <div>
                    <div className="row">
                        <div className="col-12 col-md-10 border bg-white">
                            <div className="mt-2 mb-2 d-flex align-items-center justify-content-between border-bottom my-2  border-success">
                                <div className="d-flex align-items-center w-50">
                                    <h3 className="mb-0">{isEdit ?t("navbar.editWtn"):t("navbar.createWTN")}</h3>
                                    <div className="col-md-3">
                                        <div>
                                            <h3 className="mb-0 ">
                                                {wtnState?.status?.status === 'READY_FOR_APPROVAL' && <small className="badge badge-info ml-2">{wtnState?.status?.status}</small>}
                                                {wtnState?.status?.status === "APPROVED" && <small className="badge badge-success ml-2">{wtnState?.status?.status}</small>}
                                                {wtnState?.status?.status === "DRAFT" && <small className="badge badge-secondary ml-2">{wtnState?.status?.status}</small>}
                                                {wtnState?.status?.status === "DELETED" && <small className="badge badge-danger ml-2">{wtnState?.status?.status}</small>}
                                                {wtnState?.status?.status === "ERROR" && 
                                                <small className="badge badge-danger d-inline-flex align-items-center ml-2" data-tip={wtnState?.errorMessage}>{wtnState?.status?.status}
                                                    {wtnState?.errorMessage && <FontAwesomeIcon icon={faQuestionCircle} className="ml-2" />}
                                                </small>}
                                            </h3>
                                        </div>
                                    </div>
                                    <AlertCertificate />
                                </div>
                                {
                                    wtnState?.status?.status === 'DRAFT'
                                        || wtnState?.status?.status === 'READY_FOR_APPROVAL'
                                        || wtnState?.status?.status === 'APPROVED'
                                        || wtnState?.status?.status === 'ERROR'
                                        || wtnState?.status?.status === 'DELETED'
                                        ?
                                        <div className="actions d-flex align-items-center justify-content-end">
                                            {buttons}
                                        </div>
                                        :
                                        <button
                                            className="btn btn-sm btn-success mb-2"
                                            disabled={pageState?.saving}
                                            style={{ minWidth: "80px" }}
                                            onClick={handleSave}
                                        >
                                            {pageState?.saving ? (
                                                <Loader size="xs" color="white" />
                                            ) : (
                                                t(["common.save"])
                                            )}
                                        </button>
                                }
                            </div>
                            <div className="p-xl-2">
                                <div className="row">
                                    <div className="col-12 col-md-4 border-right h-100 ">
                                        <h3 className="card-title d-flex align-items-start border-bottom pb-1">
                                            {t("invoice.general")}
                                        </h3>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label htmlFor="type">{t("customers.type")}</label>
                                                <span className="text-danger"> *</span>
                                                <Select
                                                    name="type"
                                                    id="type"
                                                    styles={customStyles}
                                                    options={typeOptions}
                                                    required
                                                    value={typeOptions.find(
                                                        (typeOption) => typeOption?.value === wtnState?.type
                                                    )}
                                                    onChange={(type) => handleInputChange(type.value, "type")}
                                                    isDisabled={!canEdit}
                                                />
                                                <label htmlFor="transaction">{t('invoice.transaction')}</label>
                                                <span className="text-danger"> *</span>
                                                <Select
                                                    name="transaction"
                                                    id="transaction"
                                                    styles={customStyles}
                                                    options={transactionOptions}
                                                    value={transactionOptions.find(
                                                        (transactionOption) =>
                                                            transactionOption?.value === wtnState?.transaction
                                                    )}
                                                    onChange={(transaction) =>
                                                        handleInputChange(transaction?.value, "transaction")
                                                    }
                                                    isDisabled={!canEdit}
                                                />
                                                <label htmlFor="issueDate">{t('table.issueDatetime')}</label>
                                                <span className="text-danger"> *</span>
                                                <DatePicker
                                                    ref={issueDateRef}
                                                    selected={wtnState?.issueDate}
                                                    dateFormat="dd/MM/yyyy "
                                                    name="issueDate"
                                                    id="issueDate"
                                                    autoComplete="off"
                                                    minDate={minIssueDate ? minIssueDate : func.getMinIssueDate()}
                                                    showDisabledMonthNavigation
                                                    onChange={(date) => {handleInputChange(date, "issueDate");handleIssueDateChange(date)}}
                                                    customInput={<input className="form-control form-control-sm" />}
                                                    disabled={!canEdit}
                                                />
                                                <label htmlFor="businUnitCode">{t("invoice.branch")}</label>
                                                <span className="text-danger"> *</span>
                                                <Select
                                                    name="businUnitCode"
                                                    id="businUnitCode"
                                                    styles={customStyles}
                                                    isSearchable={true}
                                                    options={branchesOptions}
                                                    // options={tcrOptions.filter(tcrOption => {
                                                    //     const selectedBranch = branchesOptions.find(branchesOption => branchesOption.businUnitCode === wtnState.businUnitCode)
                                                    //     return selectedBranch?.tcrTypes.find(tcrType => tcrType.tcrCode === tcrOption.value)
                                                    // })}
                                                    value={branchesOptions.find(
                                                        (branchesOption) =>
                                                            branchesOption?.businUnitCode === wtnState?.businessUnitCode
                                                    )}
                                                    onChange={(branchesOption) =>
                                                        handleBranchChange(branchesOption)
                                                    }
                                                    isDisabled={!canEdit}
                                                />
                                                <label htmlFor="tcrCode">{t("company.tcrTypes")}</label>
                                                <Select
                                                    name="tcrCode"
                                                    id="tcrCode"
                                                    styles={customStyles}
                                                    isSearchable={true}
                                                    options={tcrOptions}
                                                    value={
                                                        tcrOptions.find(
                                                            (tcrOption) => tcrOption?.value === wtnState?.tcrCode
                                                        ) || ""
                                                    }
                                                    onChange={(tcrOption) =>
                                                        handleInputChange(tcrOption.value, "tcrCode")
                                                    }
                                                    isDisabled={!canEdit}
                                                />
                                                <label htmlFor="vehiclePlate">
                                                    {t("invoice.plate")}
                                                    <span className="text-danger"> *</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    onKeyDown={handleKeyDown}
                                                    name="vehiclePlate"
                                                    id="vehiclePlate"
                                                    className={`form-control form-control-sm ${errors['vehicle.vehiclePlate'] ? "is-invalid" : ""} `}
                                                    value={wtnState?.vehicle?.vehiclePlate}
                                                    onChange={(e) =>
                                                        handleInputChange(e.target.value, "vehicle.vehiclePlate")
                                                    }
                                                    disabled={!canEdit}
                                                />
                                                <label className="field-label">
                                                    Subseq
                                                    <PopOver title={t("invoice.subseq")} />
                                                </label>
                                                <Select
                                                    components={{ Option: CustomOption }}
                                                    options={subseqValues}
                                                    value={subseqValues.find(
                                                        (subseqWtn) => wtnState?.subseq === subseqWtn?.value
                                                    )}
                                                    isDisabled={!canEdit}
                                                    styles={customStyles}
                                                    onChange={(subseqWtn) => handleSubseqChange(subseqWtn)}
                                                    name="subseq"
                                                />
                                            </div>
                                            <div className="col-md-6 ">
                                                <label htmlFor="packType">{t("invoice.packType")}</label>
                                                <span className="text-danger"> *</span>
                                                <Select
                                                    name="packType"
                                                    id="packType"
                                                    styles={customStyles}
                                                    isSearchable={true}
                                                    options={packTypes}
                                                    value={packTypes.find(
                                                        (packType) => packType?.value === wtnState?.packType
                                                    )}
                                                    onChange={(packType) =>
                                                        handleInputChange(packType.value, "packType")
                                                    }
                                                    isDisabled={!canEdit}
                                                />
                                           <label htmlFor="packNum">
                                                    {t("invoice.packNum")}
                                                    <span className="text-danger"> *</span>
                                                </label>
                                                <input
                                                    type="number"
                                                    name="packNum"
                                                    id="packNum"
                                                    min={0}
                                                    className={`form-control form-control-sm ${errors.packNum ? "is-invalid" : ""
                                                        } `}
                                                    value={wtnState?.packNum}
                                                    onChange={(e) => {
                                                        const newValue = e.target.value;
                                                        if (newValue < 0) {
                                                            document.getElementById("error-packNum").textContent = t("invoice.negativeValue");
                                                            return;
                                                        }
                                                        document.getElementById("error-packNum").textContent = "";
                                                        handleInputChange(newValue, "packNum");
                                                    }}
                                                    disabled={!canEdit}

                                                />
                                                <div id="error-packNum" className="text-danger t"></div>

                                            <label htmlFor="valueOfGoods">
                                                    {t("invoice.valueOfGoods")}
                                                    <span className="text-danger"> *</span>
                                                </label>
                                                <input
                                                    type={canEdit ? "number" : "text"}
                                                    name="valueOfGoods"
                                                    id="valueOfGoods"
                                                    min={0}
                                                    className={`form-control form-control-sm text-right ${errors.valueOfGoods ? "is-invalid" : ""
                                                        } `}
                                                    value={canEdit ? wtnState.valueOfGoods : func.formatNumber(wtnState?.valueOfGoods)}
                                                    onChange={(e) => {
                                                        const newValue = e.target.value;
                                                        if (newValue < 0) {
                                                            document.getElementById("error-valueOfGoods").textContent = t("invoice.negativeValue");
                                                            return;
                                                        }
                                                        document.getElementById("error-valueOfGoods").textContent = "";
                                                        handleInputChange(newValue, "valueOfGoods");
                                                    }}
                                                    disabled={!canEdit}
                                                />
                                                <div id="error-valueOfGoods" className="text-danger"></div>

                                                <label htmlFor="internalId"> {fullCompany?.companyAttributes?.find(a => a?.attribute?.param == "internalId")?.value ?? t("home.internalId")}</label>
                                                <input
                                                    type="text"
                                                    name="internalId"
                                                    id="internalId"
                                                    className='form-control form-control-sm'
                                                    value={wtnState?.internalId}
                                                    onChange={(e) =>
                                                        handleInputChange(e.target.value, "internalId")
                                                    }
                                                    disabled={!canEdit}
                                                />
                                                <label htmlFor="vehicleOwnership">{t("invoice.ownership")}</label>
                                                <span className="text-danger"> *</span>
                                                <Select
                                                    name="vehicleOwnership"
                                                    id="vehicleOwnership"
                                                    styles={customStyles}
                                                    isSearchable={true}
                                                    options={ownershipOptions}
                                                    value={ownershipOptions.find(
                                                        (ownershipOption) =>
                                                            ownershipOption?.value ===
                                                            wtnState?.vehicle?.vehicleOwnership
                                                    )}
                                                    onChange={(ownershipOption) =>
                                                        handleInputChange(
                                                            ownershipOption.value,
                                                            "vehicle.vehicleOwnership"
                                                        )
                                                    }
                                                    isDisabled={!canEdit}
                                                    required
                                                />
                                                <div className="mt-4">
                                                    <div className="custom-control custom-switch mr-3">
                                                        <input
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                            name="goodsFammable"
                                                            id="goodsFammable"
                                                            checked={wtnState.goodsFammable}
                                                            onChange={(e) =>
                                                                handleInputChange(e.target.checked, "goodsFammable")
                                                            }
                                                            disabled={!canEdit}
                                                        />
                                                        <label
                                                            className="custom-control-label"
                                                            htmlFor="goodsFammable"
                                                        >
                                                            {t("invoice.areFlammable")}
                                                        </label>
                                                    </div>
                                                    <div className="custom-control custom-switch mr-3">
                                                        <input
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                            name="escortRequired"
                                                            id="escortRequired"
                                                            checked={wtnState.escortRequired}
                                                            onChange={(e) =>
                                                                handleInputChange(e.target.checked, "escortRequired")
                                                            }
                                                            disabled={!canEdit}
                                                        />
                                                        <label
                                                            className="custom-control-label"
                                                            htmlFor="escortRequired"
                                                        >
                                                            {t("invoice.escortRequired")}
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4 h-100">
                                        <h3 className="card-title d-flex align-items-start border-bottom pb-1">
                                            {t("invoice.issuer")}
                                        </h3>
                                        <div className="col-md-12 px-0">
                                            <label htmlFor="sellerIdType">{t("invoice.idType")}</label>
                                            <span className="text-danger"> *</span>
                                            <input
                                                type="text"
                                                name="sellerIdType"
                                                id="sellerIdType"
                                                className="form-control form-control-sm"
                                                value={wtnState.parties.seller.sellerIDType}
                                                disabled
                                            />
                                            <label htmlFor="tin">{t("invoice.Nuis")}</label>
                                            <span className="text-danger"> *</span>
                                            <input
                                                type="text"
                                                name="tin"
                                                id="tin"
                                                className="form-control form-control-sm"
                                                value={wtnState.parties.seller.tin}
                                                disabled
                                            />
                                            <label htmlFor="sellerName">{t("customers.name")}</label>
                                            <span className="text-danger"> *</span>
                                            <input
                                                type="text"
                                                name="sellerName"
                                                id="sellerName"
                                                className="form-control form-control-sm"
                                                value={wtnState.parties.seller.name}
                                                disabled
                                            />
                                            <label htmlFor="sellerTown">{t("customers.town")}</label>
                                            <span className="text-danger"> *</span>
                                            <input
                                                type="text"
                                                name="sellerTown"
                                                id="sellerTown"
                                                className="form-control form-control-sm"
                                                value={wtnState.parties.seller.town}
                                                disabled
                                            />
                                            <label htmlFor="sellerAddress">{t("customers.address")}</label>
                                            <span className="text-danger"> *</span>
                                            <textarea
                                                rows="3"
                                                name="sellerAddress"
                                                id="sellerAddress"
                                                className="form-control form-control-sm"
                                                value={wtnState.parties.seller.address}
                                                disabled
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4  border-left h-100">
                                        <h3 className="card-title d-flex align-items-start border-bottom pb-1">
                                            {t("invoice.transporter")}
                                        </h3>
                                        <div className="col-md-12 px-0">
                                            <label htmlFor="idType">{t("invoice.idType")}</label>
                                            <span className="text-danger"> *</span>
                                            <Select
                                                name="idType"
                                                id="idType"
                                                styles={customStyles}
                                                isSearchable={true}
                                                options={idTypes}
                                                value={idTypes.find(
                                                    (idType) =>
                                                        idType.value === wtnState.parties.transporter.idType
                                                )}
                                                onChange={(idType) =>
                                                    handleInputChange(
                                                        idType.value,
                                                        "parties.transporter.idType"
                                                    )
                                                }
                                                isDisabled={!canEdit}
                                            />
                                            {wtnState?.parties?.transporter?.idType === "NUIS" ? (
                                                <>
                                                    <label className="form-label">
                                                        {wtnState.parties.transporter.idType}
                                                        <span className="text-danger"> *</span>
                                                    </label>
                                                    <form
                                                        onSubmit={(e) => {
                                                            e.preventDefault();
                                                            handleTaxPayerSearch();
                                                        }}
                                            
                                                    >
                                                        <div className="input-group position-relative">
                                                            <input
                                                                name="buyer"
                                                                type="text"
                                                                className={
                                                                    "form-control form-control-sm required" +
                                                                    (inputClass === "error" || errors['parties.transporter.idNum']
                                                                        ? " border-danger"
                                                                        : inputClass === "success"
                                                                            ? " border-success"
                                                                            : "")
                                                                }
                                                                
                                                                value={wtnState?.parties?.transporter?.idNum}
                                                                disabled={!canEdit}
                                                                onChange={handleTaxPayerChange}
                                                            />
                                                            <div className="input-group-append">
                                                                <span
                                                                    className="input-group-text cursor-pointer"
                                                                    onClick={handleTaxPayerSearch}
                                                                >
                                                                    <FontAwesomeIcon icon={faSearch} />
                                                                </span>
                                                            </div>
                                                            {inputClass === "loading" && (
                                                                <div className="input-icon input-loader">
                                                                    <Loader size="xs" />
                                                                </div>
                                                            )}
                                                            {inputClass === "error" && (
                                                                <div className="input-icon text-danger">
                                                                    <FontAwesomeIcon icon={faTimes} />
                                                                </div>
                                                            )}
                                                            {inputClass === "success" && (
                                                                <div className="input-icon text-success">
                                                                    <FontAwesomeIcon icon={faCheck} />
                                                                </div>
                                                            )}
                                                        </div>
                                                            {errors['parties.transporter.idNum']&&<div className="text-danger text-sm">{wtnState?.parties?.transporter?.idType==="NUIS"? t("toast.incorrectNipt"):t("toast.incorrectId")}</div>}
                                                    </form>
                                                </>
                                            ) : (
                                                <div>
                                                    <label htmlFor="idNum">
                                                        {wtnState.parties.transporter.idType}
                                                        <span className="text-danger"> *</span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="idNum"
                                                        id="idNum"
                                                        className={`form-control form-control-sm ${errors['parties.transporter.idNum'] ? "is-invalid" : ""} `}
                                                        value={wtnState?.parties?.transporter?.idNum}
                                                        onChange={(e) =>{
                                                            let val=e.target.value
                                                            handleInputChange(
                                                                val?val.trim():"",
                                                                "parties.transporter.idNum"
                                                            )
                                                        }
                                                        }
                                                        disabled={!canEdit}
                                                    />
                                                    {errors['parties.transporter.idNum']&&<div className="text-danger text-sm">{wtnState?.parties?.transporter?.idType==="NUIS"? t("toast.incorrectNipt"):t("toast.incorrectId")}</div>}

                                                </div>
                                            )}
                                            <label htmlFor="transporterName">
                                                {t("customers.name")}
                                                <span className="text-danger"> *</span>
                                            </label>
                                            <input
                                                type="text"
                                                name="transporterName"
                                                id="transporterName"
                                                className={`form-control form-control-sm ${errors['parties.transporter.name'] ? "is-invalid" : ""
                                                    } `}
                                                value={wtnState.parties.transporter.name}
                                                onChange={(e) =>
                                                    handleInputChange(
                                                        e.target.value,
                                                        "parties.transporter.name"
                                                    )
                                                }
                                                disabled={!canEdit}
                                            />
                                            <label htmlFor="transporterTown"> {t("customers.town")}</label>
                                            <span className="text-danger"> *</span>
                                            <Select
                                                name="transporterTown"
                                                id="transporterTown"
                                                styles={customStyles}
                                                isSearchable={true}
                                                options={cities}
                                                value={cities?.find((city) =>
                                                    diacritics?.remove(city?.value ?? "").toLowerCase() ===
                                                    diacritics?.remove(wtnState?.parties?.transporter?.town ?? "").toLowerCase()
                                                )}
                                                onChange={(city) =>
                                                    handleInputChange(city?.value, "parties.transporter.town")
                                                }
                                                isDisabled={!canEdit}
                                            />

                                            <label htmlFor="transporterAddress">
                                                {t("customers.address")}
                                                <span className="text-danger"> *</span>
                                            </label>
                                            <textarea
                                                rows="3"
                                                name="transporterAddress"
                                                id="transporterAddress"
                                                className={`form-control form-control-sm ${errors['parties.transporter.address'] ? "is-invalid" : ""
                                                    } `}
                                                value={wtnState.parties.transporter.address}
                                                onChange={(e) =>
                                                    handleInputChange(
                                                        e.target.value,
                                                        "parties.transporter.address"
                                                    )
                                                }
                                                disabled={!canEdit}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <DemoWTNtable
                                    wtnState={wtnState}
                                    canEdit={canEdit}
                                    errors={errors}
                                    customStyles={customStyles}
                                    unitsOfMeasure={unitsOfMeasure}
                                    deleteItem={deleteItem}
                                    setWtnState={setWtnState}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-md-2" >
                            <div className="pt-md-1 pt-xl-2">
                                <h3 className="card-title d-flex align-items-start border-bottom pb-1">
                                    {t("invoice.startAdr")}
                                </h3>
                                <div className="col-md-12 mb-4 px-0">
                                    <label htmlFor="startPoint">{t("invoice.startPoint")}</label>
                                    <span className="text-danger"> *</span>
                                    <Select
                                        name="startPoint"
                                        id="startPoint"
                                        styles={customStyles}
                                        isSearchable={true}
                                        options={addressPoints}
                                        value={addressPoints?.find(
                                            (addressPoint) =>
                                                addressPoint?.value === wtnState?.address[0]?.point
                                        )}
                                        onChange={(addressPoint) =>
                                            handleAddressChange(0, addressPoint.value, "point")
                                        }
                                        isDisabled={!canEdit}
                                    />
                                    <label htmlFor="startDate">
                                        {t("invoice.startDate")}
                                        <span className="text-danger"> *</span>
                                    </label>
                                    <DatePicker
                                        selected={wtnState.address[0].date}
                                        dateFormat="dd/MM/yyyy HH:mm"
                                        name="startDate"
                                        id="startDate"
                                        autoComplete="off"
                                        showTimeSelect
                                        // minDate={new Date()}
                                        showDisabledMonthNavigation
                                        onChange={(date) =>
                                            handleAddressChange(0, date, "date")
                                        }
                                        onSelect={(date) => handleAddressChange(0, date, "date",true)} 
                                        customInput={<input
                                            className={`form-control form-control-sm ${errors['address.0.date'] ? 'is-invalid' : ''} `}
                                        />}
                                        disabled={!canEdit}
                                    />
                                    <label htmlFor="startCity">{t("taxpayer.city")}</label>
                                    <span className="text-danger"> *</span>
                                    <Select
                                        name="startCity"
                                        id="startCity"
                                        styles={customStyles}
                                        isSearchable={true}
                                        options={cities}
                                        value={cities.find(
                                            (city) => city.value === wtnState.address[0].city
                                        )}
                                        onChange={(city) =>
                                            handleAddressChange(0, city.value, "city")
                                        }
                                        isDisabled={!canEdit}
                                    />
                                    <label htmlFor="startAddress">
                                        {t("customers.address")}
                                        <span className="text-danger">*</span>
                                    </label>
                                    <textarea
                                        rows="2"
                                        name="startAddress"
                                        id="startAddress"
                                        className={`form-control form-control-sm ${errors['address.0.address'] ? "is-invalid" : ""
                                            } `}
                                        value={wtnState.address[0].address}
                                        onChange={(e) =>
                                            handleAddressChange(0, e.target.value, "address")
                                        }
                                        disabled={!canEdit}
                                    />
                                </div>
                                <h3 className="card-title d-flex align-items-start border-bottom pb-1">
                                    {t("invoice.destinationAddr")}
                                </h3>
                                <div className="col-md-12 px-0">
                                    <label htmlFor="destinationPoint">
                                        {t("invoice.destPoint")}
                                    </label>
                                    <span className="text-danger"> *</span>
                                    <Select
                                        name="destinationPoint"
                                        id="destinationPoint"
                                        styles={customStyles}
                                        isSearchable={true}
                                        options={destinationPoints}
                                        value={destinationPoints.find(
                                            (addressPoint) =>
                                                addressPoint.value === wtnState.address[1].point
                                        )}
                                        onChange={(addressPoint) =>
                                            handleAddressChange(1, addressPoint.value, "point")
                                        }
                                        isDisabled={!canEdit}
                                    />
                                    <label htmlFor="destinationDate">
                                    {t("invoice.arrivalDate")}
                                        <span className="text-danger"> *</span>
                                    </label>
                                    <DatePicker
                                    style={{position:"relative"}}
                                        selected={wtnState.address[1].date}
                                        // dateFormat="dd/MM/yyyy"
                                        name="destinationDate"
                                        id="destinationDate"
                                        autoComplete="off"
                                        dateFormat="dd/MM/yyyy HH:mm"
                                        showTimeSelect
                                        showDisabledMonthNavigation
                                        onChange={(date) => handleAddressChange(1, date, "date")}
                                        onSelect={(date) => handleAddressChange(1, date, "date",true)} 
                                        customInput={<input
                                        style={{position:"relative"}}
                                            className={`form-control form-control-sm ${errors['address.1.date'] ? 'is-invalid' : ''} `}
                                        />}
                                        disabled={!canEdit}
                                    />
                                    <label htmlFor="destionationCity">{t("taxpayer.city")}</label>
                                    <span className="text-danger"> *</span>
                                    <Select
                                        name="destionationCity"
                                        id="destionationCity"
                                        styles={customStyles}
                                        isSearchable={true}
                                        options={cities}
                                        value={cities.find(
                                            (city) => city.value === wtnState.address[1].city
                                        )}
                                        onChange={(city) =>
                                            handleAddressChange(1, city.value, "city")
                                        }
                                        isDisabled={!canEdit}
                                    />
                                    <label htmlFor="destinationAddress">
                                        {t("customers.address")}
                                        <span className="text-danger"> *</span>
                                    </label>
                                    <textarea
                                        rows="2"
                                        name="destinationAddress"
                                        id="destinationAddress"
                                        className={`form-control form-control-sm ${errors['address.1.address'] ? "is-invalid" : ""
                                            } `}
                                        value={wtnState.address[1].address}
                                        onChange={(e) =>
                                            handleAddressChange(1, e.target.value, "address")
                                        }
                                        disabled={!canEdit}
                                    />
                                </div>
                            </div>

                        </div>

                        {/*  */}
                    </div>
                </div>
            </div>
        </>

    )
}

export default DemoWtn