import React, { useState, useRef, useEffect } from 'react';
import "./notifications.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faExpand } from '@fortawesome/free-solid-svg-icons';
import useClickOutside from '../../../utils/useClickOutside';
import { useAppContext } from '../../../AppContext';
import { useTranslation } from 'react-i18next';
import { TabPanel, TabView } from 'primereact/tabview';
import moment from 'moment';

const Notifications = () => {
  const mockUser = {
    firstName: "Tetra",
    lastName: "Pro",
  };

  const sampleNotifications = [
    {
      message: "System Update Available",
      description: "A new system update is available. Please update your software to the latest version to ensure you have the latest features and security patches.",
      date: "2024-12-10T10:30:00Z",
      isRead: false,
      link: "#",
      category: "General",
    },
    {
      message: "Fiscalization Error Detected",
      description: `
        **Fiscalization error:** Invalid operator code Code=86 
        RequestUUID=15995030-1740-4a7d-aa2c-dbf1571135df 
        ResponseUUID=24ce568e-e9d7-4ba3-9dc3-ab29f9230328 
        with code: 86 = Invalid operator code Code=86 
        RequestUUID=15995030-1740-4a7d-aa2c-dbf1571135df 
        ResponseUUID=24ce568e-e9d7-4ba3-9dc3-ab29f9230328
        This error indicates that the operator code provided is invalid. Please verify the operator details and retry the request. If the issue persists, contact support with the provided UUIDs for further assistance.
      `,
      date: "2024-12-12T14:45:00Z",
      isRead: false,
      link: "#",
      category: "Errors",
    },
  ];

  const [notifications, setNotifications] = useState(sampleNotifications);
  const [user] = useState(mockUser);

  const [isOpen, setIsOpen] = useState(false);
  const [expandedNotificationIndex, setExpandedNotificationIndex] = useState(null);
  const [isDescriptionLong, setIsDescriptionLong] = useState({});
  const { t } = useTranslation("translations");
  const ref = useRef();
  useClickOutside(ref, () => setIsOpen(false));

  useEffect(() => {
    const updateDescriptionHeights = () => {
      const descriptionElements = document.querySelectorAll('.notification-description');
      const updatedIsDescriptionLong = {};
      descriptionElements.forEach((element, index) => {
        updatedIsDescriptionLong[index] = element.scrollHeight > 80;
      });
      setIsDescriptionLong(updatedIsDescriptionLong);
    };

    updateDescriptionHeights();
    window.addEventListener('resize', updateDescriptionHeights);

    return () => window.removeEventListener('resize', updateDescriptionHeights);
  }, [notifications]);

  const getUserInitials = (user) => {
    const firstNameInitial = user?.firstName?.[0] ?? "";
    const lastNameInitial = user?.lastName?.[0] ?? "";
    return `${firstNameInitial}${lastNameInitial}`.toUpperCase();
  };

  const handleCheckboxClick = (event, index) => {
    event.stopPropagation();
    setNotifications((prevNotifications) =>
      prevNotifications.map((notification, i) =>
        i === index ? { ...notification, isRead: !notification.isRead } : notification
      )
    );
  };

  const handleNotificationClick = (index) => {
    setNotifications((prevNotifications) =>
      prevNotifications.map((notification, i) =>
        i === index ? { ...notification, isRead: true } : notification
      )
    );
  };

  const handleExpandClick = (index) => {
    setExpandedNotificationIndex(expandedNotificationIndex === index ? null : index);
  };

  return (
    <div ref={ref} className='notification-center'>
      <div className='notification-toggle' onClick={() => setIsOpen(isOpen => !isOpen)}>
        <div className="nav-link notif-bell d-flex">
          <FontAwesomeIcon icon={faBell} />
        </div>
        {notifications.length > 0 && (
          <div className="notification-toggle-indicator">{notifications.length}</div>
        )}
      </div>
      <div className={`notification-menu ${isOpen ? 'open' : ''}`}>
        <div className='d-flex justify-content-between align-items-center border-bottom'>
          <h2 className='notification-menu-header'>{t("navbar.notifications", "Notifications")}</h2>
          <div className="d-flex align-items-center gap-3 justify-content-center">
            <div className="custom-control custom-switch d-flex align-items-center">
              <label className="custom-control-label unread-text" htmlFor="show_only_unread">
                {t("navbar.showOnlyUnread", "Show only unread")}
              </label>
              <input type="checkbox" id="show_only_unread" className="custom-control-input" />
            </div>
            <FontAwesomeIcon
              color='#71797E'
              cursor='pointer'
              className='fullScreen-icon'
              icon={faExpand}
            />
          </div>
        </div>
        <TabView>
          {["General", "Cis", "Info", "Warning", "Errors"].map((header, headerIndex) => (
            <TabPanel header={header} key={headerIndex}>
              <div className="notification-menu-list">
                {notifications.length > 0 ? (
                  notifications.map((notification, index) => (
                    <div
                      className={`notification-menu-item ${notification.isRead ? 'notification-read-unread' : ''}`}
                      key={index}
                      onClick={() => handleNotificationClick(index)}
                    >
                      <div className="notification-initials">{getUserInitials(user)}</div>
                      <div className="notification-message">
                        <div className='notification-list'>
                          <div className='notification-msg-date'>
                            <span>{notification.message ?? t("navbar.noMessage", "No message available")}</span>
                            <span className='notification-date'>
                              {moment(notification.date).format("DD/MM/YYYY")}
                            </span>
                          </div>
                          {notification.link && (
                            <a href={notification.link} className='notification-error-link'>
                              {t("navbar.errorMessage", "Error message")}
                            </a>
                          )}
                          <div
                            className={`notification-description ${expandedNotificationIndex === index ? 'expanded' : ''}`}
                            style={{ maxHeight: expandedNotificationIndex === index ? 'none' : '80px' }}
                          >
                            {notification.description ?? t("navbar.noDescription", "No description available")}
                          </div>
                          {isDescriptionLong[index] && (
                            <span onClick={() => handleExpandClick(index)} className="expand-text">
                              {expandedNotificationIndex === index ? t("navbar.showLess", "Show less") : t("navbar.showMore", "Show more...")}
                            </span>
                          )}
                        </div>
                      </div>
                      <div
                        className={`notification-checkbox ${!notification.isRead ? 'bullet' : 'square-border'}`}
                        onClick={(event) => handleCheckboxClick(event, index)}
                      ></div>
                    </div>
                  ))
                ) : (
                  <div className='no-data-notification'>
                    <img
                      src="/notification.svg"
                      alt={t("navbar.noNotificationsImageAlt", "No notifications")}
                      style={{ width: "220px", height: "75px" }}
                    />
                    <h3>{t("navbar.noNotificationsFound", "No notifications found")}</h3>
                  </div>
                )}
              </div>
            </TabPanel>
          ))}
        </TabView>
      </div>
    </div>
  );
};

export default Notifications;
