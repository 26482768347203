import React, { useEffect, useState } from "react";
import AdditionalStatisticBox from "../Statistics/AdditionalStatisticBox";
import mainAxios from "../../services/mainAxios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsRotate, faReceipt } from "@fortawesome/free-solid-svg-icons";
import Loader from "../Layout/Loader";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useAppContext } from "../../AppContext";
import { exportData, swalAlert } from "../../services/functions";
import moment from "moment";
import { Preloader, ThreeDots } from "react-preloader-icon";

const AdditionalSaleStatistics = () => {
  const [stats, setStats] = useState({});
  const [actionLoading, setActionLoading] = useState({
    action: "",
    loading: false,
  });
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation("translations");

  const { user: [user] } = useAppContext()
  const [agentStatus, setAgentStatus] = useState(null)
  const [agentStatusLoading, setAgentStatusLoading] = useState(true)
  useEffect(() => {
    getAgentStatus()
    getAdditionalSaleStatistics()

  }, []);
  console.log({ agentStatusLoading });

  const getAdditionalSaleStatistics = () => {
    setLoading(true);
    mainAxios
      .post("apiEndpoint/search", {
        object: "GetAdditionalSaleStatistics",
      })
      .then((res) => {
        setLoading(false);
        if (res?.data?.status) {
          setStats(res.data.content[0]);
        }
      });
  }
  console.log({ agentStatus })
  const getAgentStatus = async () => {
    try {
      setAgentStatusLoading(true);
      await new Promise(resolve => setTimeout(resolve, 1200));

      const res = await mainAxios.post("ukt/agent/agentStatus", {});
      setAgentStatusLoading(false);
      if (res.data.status) {
        setAgentStatus(res.data.content[0]);
      }
    } catch (err) {
      setAgentStatusLoading(false);
    }
  };


  const startAgent = async () => {
    const res = null
    const { fromDate = Date(), toDate = Date() } = res?.data?.content ?? {}
    if (fromDate && toDate) {
      let res = await swalAlert({
        t,
        text: `Agjenti do te procesoje faturat nga data ${agentStatus?.startDate ? moment(agentStatus.startDate).format("DD/MM/YYYY HH:mm") : null} deri në ${agentStatus?.endDate ? moment(agentStatus?.endDate).format("DD/MM/YYYY HH:mm") : null}.Doni të vazhdoni?`
      })
      if (res) {
        agentAction("start")
      }
    }
  }

  const agentAction = async (action) => {
    setActionLoading({ action, loading: true });
    mainAxios
      .post("ukt/agent/" + action, {})
      .then((res) => {
        setActionLoading({ action, loading: false });
        if (res.data.status) {
          getAgentStatus()
          const message = res.data.message || t("common.actionSuccessfully");
          toast.success(message, {
            containerId: "all_toast",
          });
        }
      })
      .catch((err) => {
        console.log({ err });
        setActionLoading({ action, loading: false });
      });
  };

  const queueNumbersRequest = () => {
    setActionLoading({ action: "queueInvoices", loading: true });
    mainAxios.post("/ukt/agent/queue/invoices", {})
      .then(res => {
        setActionLoading({ action: "queueInvoices", loading: false });
        if (res.data.status) {
          const content = res.data.message
          toast.info(content, {
            containerId: "all_toast"
          })
        }
      })
      .catch(err => {
        setActionLoading({ action: "queueInvoices", loading: false });
      })
  }
  const getErrorInvoices = () => {
    setActionLoading({ action: "errorInvoices", loading: true });
    return mainAxios.post("/ukt/agent/error/invoices", {})
      .then(res => {
        setActionLoading({ action: "errorInvoices", loading: false });
        const content = res?.data?.content
        if (res.data.status) {
          return content
        }
        return null
      })
      .catch(err => {
        setActionLoading({ action: "errorInvoices", loading: false });
        return null
      })
  }

  if (loading) return <Loader />;

  const buttonLoading = ({ content, action }) => {
    if (actionLoading.action === action && actionLoading.loading) {
      return <Loader size="xs" />;
    }
    return content;
  };

  return (
    <>
      <div
        className="h-full d-flex flex-column justify-content-around"
        style={{ padding: "0px 60px" }}
      >
        <div className="d-flex flex-column justify-items-center align-items-center">
          <div className="w-full">
            <div className="row">
              <div className="col-12 col-md-4 d-flex " style={{ padding: 25 }}>
                <AdditionalStatisticBox
                  header={"# " + (stats?.lastDayTotInvoicesNo ?? 0)}
                  description={t("additionalSaleStats.lastDayTotInvoicesNo")}
                  icon={<FontAwesomeIcon icon={faReceipt} />}
                />
              </div>

              <div className="col-12 col-md-4 d-flex " style={{ padding: 25 }}>
                <AdditionalStatisticBox
                  header={"# " + (stats?.lastDaySuccessInvoicesNo ?? 0)}
                  description={t("additionalSaleStats.lastDaySuccessInvoicesNo")}
                  icon={<FontAwesomeIcon icon={faReceipt} />}
                />
              </div>

              <div className="col-12 col-md-4 d-flex " style={{ padding: 25 }}>
                <AdditionalStatisticBox
                  header={"# " + (stats?.lastDayErrorInvoicesNo ?? 0)}
                  description={t("additionalSaleStats.lastDayErrorInvoicesNo")}
                  icon={<FontAwesomeIcon icon={faReceipt} />}
                />
              </div>
              <div className="col-12 col-md-4 d-flex " style={{ padding: 25 }}>
                <AdditionalStatisticBox
                  header={"# " + (stats?.lastMinuteTotInvoicesNo ?? 0)}
                  description={t("additionalSaleStats.lastMinuteTotInvoicesNo")}
                  icon={<FontAwesomeIcon icon={faReceipt} />}
                />
              </div>

              <div className="col-12 col-md-4 d-flex " style={{ padding: 25 }}>
                <AdditionalStatisticBox
                  header={"# " + (stats?.lastMinuteSuccessInvoicesNo ?? 0)}
                  description={t(
                    "additionalSaleStats.lastMinuteSuccessInvoicesNo"
                  )}
                  icon={<FontAwesomeIcon icon={faReceipt} />}
                />
              </div>

              <div className="col-12 col-md-4 d-flex " style={{ padding: 25 }}>
                <AdditionalStatisticBox
                  header={"# " + (stats?.lastMinuteErrorInvoicesNo ?? 0)}
                  description={t("additionalSaleStats.lastMinuteErrorInvoicesNo")}
                  icon={<FontAwesomeIcon icon={faReceipt} />}
                />
              </div>
            </div>
          </div>
        </div>

        {
          user?.role?.id === 1 && process.env.REACT_APP_UKT_ACTIONS == "true" &&
          <div >
            <div className="d-flex align-items-center mb-3">
              <h3 className="mb-0">{t("additionalSaleStats.agent")}</h3>
            </div>
            {agentStatusLoading ?
              <Preloader size={45}
                use={ThreeDots}
                strokeWidth={5}
                strokeColor="#007567"
                duration={1200}
                className="my-3"
              /> :
              <>
                {agentStatus !== null && <div className="my-2 mb-4">
                  <div className="d-flex align-items-center mb-2">
                    <h4 className="p-0 m-0">Statusi </h4>
                    {agentStatus?.status == true && <div class="alert alert-success py-0 m-0 ml-2" role="alert"> Ndezur </div>}
                    {agentStatus?.status == false && <div class="alert alert-danger py-0 m-0 ml-2" role="alert"> Fikur </div>}
                    <div>
                      <FontAwesomeIcon className="ml-2" icon={faArrowsRotate} size="lg" style={{ cursor: "pointer" }} onClick={getAgentStatus} />
                    </div>
                  </div>
                  {agentStatus?.startDate && agentStatus?.endDate && <div className="d-flex ">
                    <h4 className="p-0 m-0 mr-2">Faturat nga Data: </h4>
                    <div style={{ fontWeight: "600" }}>
                      <span style={{ fontWeight: "600", textDecoration: "underline" }}>{agentStatus?.startDate ? moment(agentStatus.startDate).format("DD/MM/YYYY HH:mm") : null}</span > deri më <span style={{ fontWeight: "600", textDecoration: "underline" }}>{agentStatus?.endDate ? moment(agentStatus?.endDate).format("DD/MM/YYYY HH:mm") : null}</span>
                    </div>
                  </div>}
                </div>}
              </>

            }
            <div className="d-flex flex-wrap">
              <button
                style={{ width: 150 }}
                className="btn btn-primary mb-2 mr-2"
                onClick={() => startAgent()}
                disabled={actionLoading.loading}
              >
                {buttonLoading({ content: "Start", action: "start" })}
              </button>
              <button
                style={{ width: 150 }}
                className="btn btn-danger mb-2 mr-2"
                onClick={() => agentAction("stop")}
                disabled={actionLoading.loading}
              >
                {buttonLoading({ content: "Stop", action: "stop" })}
              </button>
              <button
                style={{ width: 150 }}
                className="btn btn-warning mb-2 mr-2"
                onClick={() => agentAction("resend/invoices")}
                disabled={actionLoading.loading}
              >
                {buttonLoading({ content: "Resend", action: "resend" })}
              </button>
              <button
                style={{ width: 150 }}
                className="btn btn-info mb-2 mr-2"
                onClick={() => queueNumbersRequest()}
                disabled={actionLoading.loading}
              >
                {buttonLoading({ content: t("additionalSaleStats.invoicesNumber"), action: "queueInvoices" })}
              </button>
              <button
                // style={{ width: 1 }}
                className="btn btn-dark mb-2"
                onClick={() => exportData({ fetchFunc: getErrorInvoices, showExportToast: false })}
                disabled={actionLoading.loading}
              >
                {buttonLoading({ content: "Export Error Invoices", action: "errorInvoices" })}
              </button>
            </div>
          </div>
        }

      </div>
    </>

  );
};

export default AdditionalSaleStatistics;
