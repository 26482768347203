/* eslint-disable jsx-a11y/anchor-is-valid */
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useAppContext, usePersistedState } from "../../AppContext";
import func, { removePropertiesRecursive, validateInvoice } from "../../services/functions";
import mainAxios from "../../services/mainAxios";
import InvoiceStatus from "../Invoice/columns/InvoiceStatus";
import Loader from "../Layout/Loader";
import UploadInvoice from "../Invoice/UploadInvoice";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import generateCustomInvoiceColumns from "./generateCustomInvoiceColumns";
import invoiceActions from "../../services/invoiceActions.json";
import invoiceStatuses from "../../services/invoiceStatuses.json";
import invoiceTypes from "../../services/InvoiceTypes.json";
import ThermalPrinterInvoiceContent from "./ThermalPrinterInvoiceContent";
import Swal from "sweetalert2";
import selectStyle from "../../design/selectStyle";
import generatePosColumns from "../MyPos/components/generatePosColumns";
import DateRange from "../global/DateRange";
import AlertCertificate from "../global/AlertCertificate";
import AlertSubscription from "../global/AlertSubscription";
import PrimeTable from "../PrimeTable/PrimeTable";
import { Column } from "primereact/column";
import ExportCsvPrime from "../global/ExportCsvPrime";
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from "primereact/dialog";
import {  globalFilterStatisticCard } from "../global/StatisticsCards";
import { defaultTableRows } from "../../services/data";
import useShowInvoicePdf from "../global/useShowInvoicePdf";
import ExportCsvMultiple from "../global/ExporCsvMultiple";


const moment = require("moment");

const CustomInvoicesNew = (props) => {
    const { t,i18n } = useTranslation("translations");
    const {
        user: [user],
        selectedCompany: [selectedCompany],
        company: [fullCompany],
        dates: [dates, setDates],
        refreshStatuses
    } = useAppContext();
    const { history, isPos } = props;

    const company = user.companiesRights.find(
        (company) => company.id === parseInt(selectedCompany)
    );
    const [persistedData, setPersistedData] = usePersistedState(
        null,
        "sessionData"
    );

    const [persistedRowPerPage, setPersistedRowPerPage,] = usePersistedState(
        10,
        "rowsPerPage"
    );



    const businessUnitCodes = React.useMemo(() => {
        return [{
            label: t("invoice.businessUnitCode"),
            value: null,
        }].concat(company.branches.map((branch) => {
            return {
                label: branch.businUnitCode + " | " + branch.sellerName,
                value: branch.businUnitCode,
            };
        }))
    }, [selectedCompany])

    const businessUnitSelected = businessUnitCodes.some(
        (branch) => branch?.value === persistedData?.businessUnitCode
    )
        ? persistedData?.businessUnitCode
        : null;

    const filterParams = {
        businessUnitCode: businessUnitSelected,
        currency: [],
        type: [],
        internalId: null,
        isEinvoice: null,
        isDeleted: "false",
        opCode: null,
        tin: null,
        recordUser: null,
        paymentMethodType: null,
        correctiveInvType: null,
        statusObj: [],
        isForeign: null,
        source: isPos ? "MP" : "MI",
        tcrCode: null,
    };

    const [expandedData, setExpandedData] = useState([]);
    const [expandedRows, setExpandedRows] = useState(null);
    const [loadingExpandData, setLoadingExpandData] = useState(false);

    const [selectedRows, setSelectedRows] = useState([]);
    const [actionLoading, setActionLoading] = useState(false)
    const [refresh, setRefresh] = useState(false)
    const [spinLoading, setSpinLoading] = useState(false)


    const uIOptions = [
        { value: "eic", label: "EIC" },
        { value: "internalId", label: fullCompany?.companyAttributes?.find(a => a?.attribute?.param == "internalId")?.value ?? "ERP ID" },
        { value: "iic", label: "IIC" },
        { value: "number", label: "UBLID" },
        { value: "param1", label: fullCompany?.companyAttributes?.find(a => a?.attribute?.param == "AdditionalId1")?.value ?? (t("pos.additional") + " 1") },
        { value: "param2", label: fullCompany?.companyAttributes?.find(a => a?.attribute?.param == "AdditionalId2")?.value ?? (t("pos.additional") + " 2") },
    ];

    const [invoices, setInvoices] = React.useState(null);
    const [invoicesForCSV, setInvoicesForCSV] = useState([]);
    const [loading, setLoading] = React.useState(false);
    const [uIType, setUIType] = useState({
        value: "internalId",
        label: fullCompany?.companyAttributes?.find(a => a?.attribute?.param == "internalId")?.value ?? "ERP ID",
        uidValue: null,
    });

    const [statistics, setStatistics] = useState(null);
    const [openUploadInvoice, setOpenUploadInvoice] = useState(false);
    const [currentInvoiceIndex, setCurrentInvoiceIndex] = React.useState(null);


    const defaultSortCriteria={
        field: "issueDate",
        direction: "DESC"
    }

    const [tableRows, setTableRows] = useState({
        ...defaultTableRows,
        rowsPerPage:persistedRowPerPage,
        sortCriteria:defaultSortCriteria
    });

    const defaultFilters = {
        fromDate: dates.fromDate,
        toDate: dates.toDate,
        params: {
            ...filterParams,
        },
    };
  const { showInvoicePdf,pdfEmbed,loadingPdf } = useShowInvoicePdf()


    const [filters, setFilters] = React.useState(defaultFilters);

    React.useEffect(() => {
        getFilteredResults({data:defaultFilters});
    }, [selectedCompany, refresh]);

    
    const getDataFromInvoiceRequest = (invoice) => {
        return mainAxios.post("apiEndpoint/search", {
             object: "GetCustomInvoice",
             params: {
                 id: String(invoice?.id)
             }
         }).then(res => {
             if (res?.data?.status) {
                const parsedInvoice = func.parseCustomInvoiceResponse(
                    res.data.content[0]
                  );
                return parsedInvoice
             }
             return null
         }).catch((error)=>{
           return null
         })
     }


    const handleDuplicateInvoice = async (e,invoice) => {
        e.preventDefault()
        setSpinLoading(true)
        const newState = await getDataFromInvoiceRequest(invoice)
        if(!newState) return
        const validateInvoiceFields = validateInvoice({ setErrors, toast, state: newState });
        if (!validateInvoiceFields) return;
        const {
            recordDate,
            recordUser,
            modificationDate,
            modificationUser,
            source,
            customInvoice,
            invoiceHistorics,
            attachmentPath,
            invoiceExtraCharge,
            invoicePrepaidAmount,
            iic,
            fic,
            eic,
            badDebtInv,
            sumInv,
            param3,
            param4,
            qrurl,
            cisStatus,
            cisError,
          ...restInvoice
        } = newState;

        const pathsToPreservId = ['items','bankAccount'];
        const cleanedRestInvoice = removePropertiesRecursive(restInvoice, pathsToPreservId);
        cleanedRestInvoice.action = {
          "id": 5,
          "action": "CREATED"
        };

        cleanedRestInvoice.status = {
          "id": 7,
          "status": "DRAFT"
        };

        cleanedRestInvoice.items = cleanedRestInvoice.items.map(items => {
          const { id,...restItems } = items
          return restItems
        })


        cleanedRestInvoice.paymentMethod = cleanedRestInvoice?.paymentMethod?.map(pm => {
          const {
            modificationDate,
            modificationUser,
            customInvoice,
            recordUser,
            recordDate,
            ...restPm } = pm
            return restPm
        }) ?? []

        cleanedRestInvoice.issueDate = new Date()
        cleanedRestInvoice.dueDate = new Date();
        cleanedRestInvoice.internalId = null
        cleanedRestInvoice.opCode = user?.userCompanyOperator?.find(
            (companyOperator) => companyOperator.company === company.id
          )?.operator || null
         
          if (cleanedRestInvoice.businessUnitCode) cleanedRestInvoice.businessUnitCode = cleanedRestInvoice.businessUnitCode.trim();
          if (cleanedRestInvoice.opCode) cleanedRestInvoice.opCode = cleanedRestInvoice.opCode.trim();
          if (cleanedRestInvoice.parties.buyer.name) cleanedRestInvoice.parties.buyer.name = cleanedRestInvoice.parties.buyer.name.trim();
          if (cleanedRestInvoice.parties.seller.name) cleanedRestInvoice.parties.seller.name = cleanedRestInvoice.parties.seller.name.trim();

        mainAxios
          .post("endpoint/v2.2/saveOrUpdate", {
            object: "CustomInvoice",
            content: {
              ...func.parseCustomInvoiceRequest(cleanedRestInvoice),
            },
            nuis: company.nuis.trim(),
          })
          .then((res) => {
                 setSpinLoading(false)
            if (res?.data?.status === true) {
                //   const duplicatedInvoice = func.parseCustomInvoiceResponse(res.data.content[0]);
                  props.history.push("/edit-invoice/" + res.data.content[0].id);
                  toast.success(t(['invoice.isInvoiceDuplicated']), { containerId: "all_toast" })
            }
          })
          .catch(() => {
            setSpinLoading(true)
        });
      };


    const getFilteredResults = ({
        data,
        pageSize = (persistedRowPerPage?? defaultTableRows.rowsPerPage),
        page = defaultTableRows.currentPage,
        clearFilters = false,
        getInvoicesForCVS = false,
        sortCriteria = [ defaultSortCriteria ]
    }) => {
        const requestFilters = data ? data : filters;
        if (!getInvoicesForCVS) setLoading(true);
        if (invoicesForCSV.length > 0) setInvoicesForCSV([]);
        if (uIType?.uidValue && !clearFilters) {
            let val;
            val = uIType.uidValue;
            requestFilters.params = {
                ...requestFilters.params,
                [uIType.value]: val,
            };
        }

        let params={
            ...requestFilters.params,
            number: requestFilters.params.number
                ? requestFilters.params.number + "<L>"
                : null,
            type: requestFilters.params.type.join(",") || null,
            internalId: requestFilters.params.internalId
                ? requestFilters.params.internalId + "<L>"
                : null,
            name: requestFilters.params.name
                ? requestFilters.params.name + "<L>"
                : null,
            tin: requestFilters.params.tin
                ? requestFilters.params.tin + "<L>"
                : null,
            recordUser: requestFilters.params.recordUser
                ? requestFilters.params.recordUser + "<L>"
                : null,
            profileId: requestFilters.params.profileId?.join(",") || null,
            statusObj: requestFilters.params.statusObj?.join(",") || null,
            currency: requestFilters.params.currency?.join(",") || null,
            param1: requestFilters.params.param1
                ? requestFilters.params.param1 + "<L>"
                : null,
            param2: requestFilters.params.param2
                ? requestFilters.params.param2 + "<L>"
                : null,
        }

        if(getInvoicesForCVS){
            const {isDeleted, param1, param2,...restParams } = params
            params=restParams
        }


        return mainAxios
            .post("apiEndpoint/search", {
                object:getInvoicesForCVS?"CustomInvoiceResponseNewV" : "GetCustomInvoiceV",
                ...requestFilters,
                params,
                pagination: getInvoicesForCVS
                    ? null
                    : {
                        pageSize,
                        pageNumber: page,
                    },
                    sortCriteria
            })
            .then((res) => {
                if (res?.data?.status === true) {
                    if (getInvoicesForCVS) {
                        setInvoicesForCSV(res.data.content);
                    } else {
                        setInvoices(
                            res.data.content
                                .map((invoice) => {
                                    return func.parseCustomInvoiceResponse(invoice);
                                })
                                .map((invoice) => ({
                                    ...invoice,
                                    invoiceHistorics: invoice.invoiceHistorics?.sort(
                                        (a, b) => b.recordDate - a.recordDate
                                    ),
                                }))
                        );
                        setTableRows((tableRows)=>({
                            ...tableRows,
                            totalRows: res?.data?.totalSize,
                        }));
                    }
                    if (!getInvoicesForCVS) setLoading(false);
                    return true;
                } else {
                    setInvoices([]);
                    if (!getInvoicesForCVS) setLoading(false);
                    return false;
                }
            })
            .catch((err) => {
                setLoading(false);
                return false;
            });
    };



    const handleParamsChange = (e, label, val) => {
        const key = e ? e.target.name : label;
        const value = e ? e.target.value : val;
        if (uIOptions?.map((op) => op.value).includes(key)) {
            setUIType({
                value: key,
                label: uIOptions?.find((op) => op.value == key)?.label,
                uidValue: value,
            });
        } else {
            setFilters((filters) => ({
                ...filters,
                params: {
                    ...filters.params,
                    [key]: value,
                },
            }));
        }
    };


    const handleInvoiceDelete = (e, invoice) => {
        e.preventDefault();
        setLoading(true);
        delete invoice["deleted"];
        delete invoice["foreign"];
        delete invoice["subseq"];
        mainAxios
            .post("apiEndpoint/saveOrUpdate", {
                object: "CustomInvoice",
                content: {
                    ...func.parseCustomInvoiceRequest(invoice),
                    action: invoiceActions[4],
                    status: invoiceStatuses[3],
                },
                nuis: company.nuis,
            })
            .then((res) => {
                if (res?.data?.status === true) {
                    toast.success(t(["toast.draftDeleted"]), {
                        containerId: "all_toast",
                    });
                    const index = invoices.findIndex(
                        (customInvoice) => customInvoice.id === invoice.id
                    );
                    setInvoices((invoices) => [
                        ...invoices.slice(0, index),
                        ...invoices.slice(index + 1),
                    ]);
                }
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
    };

    const handleThermalPrint = (id) => {
        let index = invoices.findIndex((invoice) => invoice.id === id);
        if (index === currentInvoiceIndex) {
            printThermalInvoice();
        } else {
            setCurrentInvoiceIndex(
                invoices.findIndex((invoice) => invoice.id === id)
            );
        }
    };
    const firstUpdate = React.useRef(true);

    const printThermalInvoice = () => {
        const content = document.getElementById("thermalPrinterInvoice");
        const pri = document.getElementById("ifmcontentstoprint").contentWindow;
        pri.document.open();
        pri.document.write(
            `<html><style>@page{margin:0mm auto;margin-bottom:10mm;font-family: 'EB Garamond', serif;}</style>${content.innerHTML}</html>`
        );
        pri.document.close();
        pri.focus();
        pri.print();
    };
    React.useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }
        printThermalInvoice();
    }, [currentInvoiceIndex]);

    React.useEffect(() => {
        if (!(filters?.fromDate && filters?.toDate)) {
            return;
        }
        let endpoint = persistedData?.businessUnitCode
            ? "/endpoint/v2/reports/myinvoice/branch/aggregate"
            : "/endpoint/v2/reports/myinvoice/company/aggregate";
        if (isPos)
            endpoint = persistedData?.businessUnitCode
                ? "/endpoint/v2/reports/mypos/branch/aggregate"
                : "/endpoint/v2/reports/mypos/company/aggregate";

        mainAxios
            .post(endpoint, {
                object: "GetCustomInvoiceCashDepositStatisticsAnalytics",
                fromDate: moment(filters?.fromDate).format("YYYY-MM-DD"),
                toDate: moment(filters?.toDate).format("YYYY-MM-DD"),
                ...(filters?.params?.businessUnitCode
                    ? { params: { businessUnitCode: filters?.params?.businessUnitCode } }
                    : {}),
            })
            .then((res) => {
                if (res?.data?.status) {
                    let getStatistics = calculateStatistics(res.data.content);
                    setStatistics(getStatistics);
                }
            });
    }, [
        filters?.fromDate,
        filters?.toDate,
        filters?.params?.businessUnitCode,
        selectedCompany,
    ]);

    const calculateStatistics = (data) => {
        if (data?.length > 0) {
            const {
                id,
                tcrCode,
                businessUnitCode,
                actionFrom,
                company,
                recordDate,
                ...rest
            } = data[0];
            let stats = {};
            Object.keys(rest).forEach((key) => {
                stats[key] = data.reduce((sum, tcrStat) => sum + tcrStat[key], 0);
            });
            return stats;
        }
        return [];
    };

    const handleInvoiceCancel = (e, invoice) => {
        e.preventDefault();
        
        Swal.fire({
            title: t("invoice.cancelInvoice"),
            confirmButtonText: "Yes",
            confirmButtonColor: "#d33",
            cancelButtonColor: "#007567",
            showCancelButton: true,
            cancelButtonText: "No",
        }).then((res) => {
            if (res.isConfirmed) {
                setLoading(true);
                const correctiveInvoice = JSON.parse(JSON.stringify(invoice));
                delete correctiveInvoice["id"];
                delete correctiveInvoice["invoiceHistorics"];
                delete correctiveInvoice["internalId"];
                delete correctiveInvoice["deleted"];
                delete correctiveInvoice["foreign"];
                delete correctiveInvoice["subseq"];
                const opCode=user?.userCompanyOperator?.find(
                    (uco) => parseInt(uco.company) === parseInt(selectedCompany)
                  )?.operator ?? correctiveInvoice.opCode

                correctiveInvoice.opCode = opCode
                if(correctiveInvoice?.discount?.id) delete correctiveInvoice.discount.id

                correctiveInvoice.attachments = correctiveInvoice.attachments.map(
                    (attachment) => {
                        const { id, ...rest } = attachment;
                        return rest;
                    }
                );
                correctiveInvoice.items = correctiveInvoice.items.map((item) => {
                    const { id, quantity, totalWithoutVat, totalWithVat, ...rest } = item;

                    return {
                        ...rest,
                        quantity: -1 * quantity,
                    };
                });

                if (correctiveInvoice?.paymentMethod?.length > 0) {
                    correctiveInvoice.paymentMethod = correctiveInvoice.paymentMethod.map(payment => {
                        return {
                            ...payment,
                            id:null,
                            paymentAmount: -1 * payment.paymentAmount
                        }
                    })
                }
                mainAxios
                    .post("/endpoint/v2.2/saveOrUpdate", {
                        object: "CustomInvoice",
                        content: {
                            ...func.parseCustomInvoiceRequest(correctiveInvoice),
                            issueDate: new Date(),
                            dueDate: new Date(),
                            action: invoiceActions[0],
                            status: invoiceStatuses[0],
                            iic: null,
                            fic: null,
                            eic: null,
                            recordDate: null,
                            recordUser: null,
                            number: "",
                            profileId: "P10",
                            type: "384",
                            correctiveInv: true,
                            correctiveInvType: "CORRECTIVE",
                            badDebtInv: false,
                            reverseCharge: false,
                            selfInvoice: false,
                            sumInv: false,
                            cisStatus: null,
                            cisError: null,
                            internalId: "",
                            iic_ref: correctiveInvoice.iic,
                            refInvIIC: correctiveInvoice.iic,
                            refInvIssueDateTime: invoice.issueDate,
                        },
                        nuis: company.nuis,
                    })
                    .then((res) => {
                        if (res?.data?.status === true) {
                            toast.success("Corrective Invoice Created!", {
                                containerId: "all_toast",
                            });
                            if (props?.isPos) {
                                history.push(
                                    "/pos-transactions/edit/" + res?.data?.content[0].id
                                );
                            } else {
                                history.push("/edit-invoice/" + res?.data?.content[0].id);
                            }
                        }
                        setLoading(false);
                    })
                    .catch(() => {
                        setLoading(false);
                    });
            }
        });
    };


    const operatorCodes = [
        {
            label: t("invoice.selectOptions"),
            value: null,
        },
    ]
        .concat(
            [
                ...new Set(
                    company.branches
                        .reduce((acc, next) => acc.concat(next.tcrTypes), [])
                        .map((tcrType) => tcrType.operatorCode)
                ),
            ].map((operatorCode) => ({
                label: operatorCode,
                value: operatorCode,
            }))
        )
        .concat(userCompanyOperator);

    const invoiceTypeOptions = invoiceTypes
        .filter((invoice) => invoice.disabled == false)
        .map((type) => {
            return {
                ...type,
                label: t([type.label]),
            };
        });



    const [errors,setErrors]=useState([])

    const isRefreshStatus=useCallback((invoice)=>{
        let status = invoice?.status?.status;
        let resendStatuses = refreshStatuses?.filter(resendStatus=>resendStatus?.isActive==true)?.map(resendStatus=>resendStatus?.status)?? []
        let staticRefreshConditions=invoice?.einvoice && invoice?.cisStatus==="SUCCESS" && (!invoice?.eic || invoice.eic=="")
    
        return  (resendStatuses.includes(status)) || staticRefreshConditions
    },[refreshStatuses])

    const columns = isPos
        ? generatePosColumns(
            {
                showInvoicePdf,
                loadingPdf,
                handleInvoiceDelete,
                handleInvoiceCancel,
                t,
                i18n,
                handleThermalPrint,
                isPos,
                invoiceTypeOptions,
                operatorCodes,
                invoices,
                isRefreshStatus
            }
        )
        : generateCustomInvoiceColumns(
            {
                showInvoicePdf,
                loadingPdf,
                handleInvoiceDelete,
                handleInvoiceCancel,
                t,
                i18n,
                handleThermalPrint,
                isPos,
                invoiceTypeOptions,
                operatorCodes,
                setErrors,
                setLoading,
                handleDuplicateInvoice,
                spinLoading,
                invoices,
                isRefreshStatus
            }
        )

    const [selectedColumns, setSelectedColumns] = usePersistedState(
        columns.map((col) => ({field:col.field,show:!!col.default,...(col?.persisted?{persisted:true}:{})})),
        isPos?"posInvoiceColumnsPrime4":"customInvoiceColumnsPrime4"
    );


    const userCompanyOperator = user?.userCompanyOperator?.map((userOpCode) => ({
        label: userOpCode.operator,
        value: userOpCode.operator,
    })) ?? [];


    const typeOptions = [
        {
            key: "isEinvoice",
            count: statistics?.onlyFiscalizationInvoicesNo,
            label: t("parameters.onlyFiscalization"),
            value: "false",
        },
        {
            key: "isEinvoice",
            count: statistics?.einvoiceInvoicesNo,
            label: t("invoice.eInvoicesOption"),
            value: "true",
        },
    ];

    const typeOptionsPos = [
        {
            key: "paymentMethodType",
            count: statistics?.cashInvoicesNo,
            label: "Cash",
            value: "cash",
        },

        {
            key: "paymentMethodType",
            count: statistics?.noncashInvoicesNo,
            label: "Non Cash",
            value: "noncash",
        },
    ];
    const statusOptions = [
        {
            key: "statusObj",
            count: statistics?.draftInvoicesNo,
            multiSelect: true,
            label: t("status.draft"),
            value: "7",
        },
        {
            key: "statusObj",
            count: statistics?.approvedInvoicesNo,
            multiSelect: true,
            label: t("status.approved"),
            value: "9",
        },
        {
            key: "statusObj",
            count: statistics?.errorInvoicesNo,
            multiSelect: true,
            label: "Error",
            value: "35",
        },
        {
            key: "statusObj",
            count: statistics?.retryInvoicesNo,
            multiSelect: true,
            label: "Retry",
            value: "36",
        },
        {
            key: "statusObj",
            count: statistics?.readyForApprovalInvoicesNo,
            multiSelect: true,
            label: t("status.readyForApproval"),
            value: "8",
        },
    ];

    const statusOptionsPos = [
        {
            key: "statusObj",
            multiSelect: true,
            label: t("status.draft"),
            count: statistics?.draftInvoicesNo,
            value: "7",
        },
        {
            key: "statusObj",
            count: statistics?.approvedInvoicesNo,
            multiSelect: true,
            label: t("status.approved"),
            value: "9",
        },
        {
            key: "statusObj",
            count: statistics?.retryInvoicesNo,
            multiSelect: true,
            label: "Retry",
            value: "36",
        },
        {
            key: "statusObj",
            count: statistics?.errorInvoicesNo,
            multiSelect: true,
            label: t("home.error"),
            value: "35",
        },
    ];

    const currencyOptions = [
        {
            key: "currency",
            count: statistics?.allCurrencyInvoicesNo,
            multiSelect: true,
            label: "Lek",
            value: "ALL",
        },
        {
            key: "currency",
            count: statistics?.usdCurrencyInvoicesNo,
            multiSelect: true,
            label: "USD",
            value: "USD",
        },
        {
            key: "currency",
            count: statistics?.eurCurrencyInvoicesNo,
            multiSelect: true,
            label: "Euro",
            value: "EUR",
        },
    ];

    const currencyOptionsPos = [
        {
            key: "currency",
            count: statistics?.allCurrencyInvoicesNo,
            multiSelect: true,
            label: "Lek",
            value: "ALL",
        },
        {
            key: "currency",
            count: statistics?.usdCurrencyInvoicesNo,
            multiSelect: true,
            label: "USD",
            value: "USD",
        },
        {
            key: "currency",
            count: statistics?.eurCurrencyInvoicesNo,
            multiSelect: true,
            label: "Euro",
            value: "EUR",
        },
    ];

    const otherOptions = [
        {
            key: "isForeign",
            count: statistics?.foreignInvoicesNo,
            label: t("common.foreign"),
            value: "true",
        },
        {
            key: "type",
            multiSelect: true,
            count: statistics?.selfInvoicesNo,
            label: t("invoice.selfInvoice"),
            value: "389",
        },
        {
            key: "paymentMethodType",
            count: statistics?.cashInvoicesNo,
            label: "Cash",
            value: "cash",
        },
        {
            key: "isDeleted",
            count: statistics?.deletedInvoicesNo,
            label: t("invoice.showDeleted"),
            value: "true",
            isBoolean: true,
        },
    ];
    const otherOptionsPos = [
        {
            key: "isEinvoice",
            count: statistics?.onlyFiscalizationInvoicesNo,
            label: t("parameters.onlyFiscalization"),
            value: "false",
        },
        {
            key: "paymentMeans",
            count: statistics?.cardPaymentInvoicesNo,
            label: "Cards",
            value: "48",
        },
        {
            key: "isDeleted",
            count: statistics?.deletedInvoicesNo,
            label: t("invoice.showDeleted"),
            value: "true",
            isBoolean: true,
        },
    ];


    const userCanCreate = [1, 2, 4].includes(user.role.id);

    const filterStatisticCard=(typeOptions,props)=>globalFilterStatisticCard(typeOptions,{...props,handleParamsChange,getFilteredResults,user,filters})


    const expandedColumns = [
        {
            field: "",
            header: "",
            // style: { minWidth: 150, maxWidth: 150 },
        },
        {
            field: "invoiceAction",
            header: t(["home.actions"]),
            // style: { minWidth: 150, maxWidth: 150 },
            sortable: true,
            body: row => row?.invoiceAction?.action ?? ""
        },
        {
            field: "invoiceStatus",
            header: "Status",
            // style: { minWidth: 150, maxWidth: 150 },
            sortable: true,
            body: row => row?.invoiceStatus?.status ?? "",


        },
        {
            field: "cisStatus",
            header: "Cis Status",
            // style: { minWidth: 150, maxWidth: 150 },
            sortable: true,

        },
        {
            field: "recordDate",
            header: t(["home.date"]),
            body: (row) => {
                return row?.recordDate ? moment(row?.recordDate).format("DD/MM/YYYY HH:mm") : ""
            },
            // style: { minWidth: 150, maxWidth: 150 },


        },
        {
            field: "recordUser",
            header: t(["logs.user"]),
            // style: { minWidth: 150, maxWidth: 150 },

        },

    ];
    const rowExpansionTemplate = () => {
        const {
            fiscStatus,
            einvoiceStatus,
            ublInvoiceTypeCode,
            correctiveInvoiceType,
            number,
            type,
            status,
            cisStatus
        } = expandedData?.[0] ?? {};

        if (loadingExpandData) {
            return <div style={{ width: "100vw" }} className="d-flex justify-content-center">
                <Loader />
            </div>
        }
        return (
            <div style={{ width: "100vw" }} className="my-4 ">
                <div className="d-flex align-items-center mb-4">
                    <div style={{ fontSize: 16, fontWeight: 600, marginLeft: 55, marginRight: 20 }}>
                        Status
                    </div>
                    <InvoiceStatus
                        fiscStatus={fiscStatus}
                        einvoiceStatus={einvoiceStatus}
                        ublInvoiceTypeCode={type}
                        correctiveInvoiceType={correctiveInvoiceType}
                        isRefreshStatus={isRefreshStatus}
                    />
                </div>
                <div className="body p-0" >
                    <PrimeTable
                        value={expandedData?.[0]?.invoiceHistorics ?? []}
                        columns={expandedColumns}
                        showFilters={false}
                        paginator={false}
                        filterDisplay={false}
                        responsiveLayout="scroll"
                    />
                </div>
            </div>

        );
    };


    const swalAlert = async ({ title }) => {

        return Swal.fire({
            title,
            text: t("invoice.responseDelay"),
            confirmButtonText: t("company.yes"),
            confirmButtonColor: "#007567",
            cancelButtonColor: "#d33",
            showCancelButton: true,
            cancelButtonText: t("company.no"),
        }).then((res) => {
            if (res.isConfirmed) {
                return true;
            } else {
                return false
            }
        });
    }


    const approveGroupedInvoices = ({ content }) => {
        setActionLoading(true)
        return mainAxios.post("/apiEndpoint/saveOrUpdate",
            {
                object: "GroupedCustomInvoice",
                content,
                nuis: company?.nuis,
            },
            { timeout: 180000 }
            ,
        ).then((res) => {
            setActionLoading(false)

            if (res.data.status) {
                setRefresh((refresh) => !refresh)
                setSelectedRows([])
                toast.success(t(["common.actionSuccessfully"]), {
                    containerId: "all_toast",
                })
            }
        })
            .catch(err => {
                setActionLoading(false)
                return false
            })
    }


    const headerButtonsRight = (
        selectedRows.length > 0 && <div className="d-flex align-items-center">
            <button className="btn btn-outline-primary mr-3"
                disabled={actionLoading}
                onClick={async () => {
                    if (actionLoading) return;
                    let res = await swalAlert({ title: t("purchases.approveAllWarning") })
                    if (res) {
                        const approvedInvoices = selectedRows ?? []
                        approveGroupedInvoices({ content: approvedInvoices })
                    }
                }}>
                {actionLoading ? <Loader size="xs" /> : t(["purchases.approveAll"])}
            </button>
            <button className="btn btn-outline-primary mr-3"
                disabled={actionLoading}
                onClick={async () => {
                    if (actionLoading) return;
                    setSelectedRows([])
                }}>
                {actionLoading ? <Loader size="xs" /> : t("common.clear")}
            </button>
        </div>
    )


    const onRowExpand = (e) => {
        setLoadingExpandData(true);
        setExpandedRows([e.data]);
        mainAxios
            .post("apiEndpoint/search", {
                object: "GetInvoice",
                params: {
                    ublId: e?.data?.number?.toString(),
                },
            })
            .then((res) => {
                if (res.data.status) {
                    const newData = res.data.content?.[0] ?? {}
                    setExpandedData([{
                        ...e.data,
                        ...newData
                    }]);
                    setLoadingExpandData(false);
                }
            });
    }

    const approveMultiInovicesBody=(invoice)=>{
        if(![7, 8].includes(invoice?.status?.id)) return<></>
        return <Checkbox 
            onChange={
            (e) => {
                setSelectedRows((state)=>{
                    const _state=[...state]
                    const checkedValueIndex =_state.findIndex(sRow=>sRow==invoice?.id)
                    if(checkedValueIndex>=0) {
                        _state.splice(checkedValueIndex,1)
                        return _state
                    }
                    _state.push(invoice.id)
                    return _state
                })
            }
        } 
        checked={!!selectedRows.find(sRow=>sRow==invoice?.id)} 
        
        />
    }

    const approveMultiInovicesFilter=useMemo(()=>{
        const showFilter = invoices?.filter(invoice=>[7, 8].includes(invoice?.status?.id)) ?? []
        if(showFilter?.length==0) return <></> 
        return <Checkbox
            onChange={
                (e) => {
                    if(e.checked){
                        const _selectedRows=[]
                        invoices.forEach(invoice =>{
                            if([7, 8].includes(invoice?.status?.id)){
                                _selectedRows.push(invoice?.id)
                            }
                        })
                        setSelectedRows(_selectedRows)
                    }else{
                        setSelectedRows([])
                    }
                }
            }
            checked={selectedRows.length == showFilter?.length}
        />
    }, [invoices, selectedRows])

    return invoices ? (
        <>
            <div className="d-flex flex-wrap justify-content-between align-items-center my-1">
                <div className="left-header d-flex align-items-center  mb-3">
                    <h3 className="mb-0 p-0">
                        {isPos ? t("pos.posTransaction") : t("invoice.custom")}
                    </h3>
                    <small className="ml-2">
                    <ExportCsvMultiple
                            data={invoicesForCSV}
                            selectedColumns={selectedColumns}
                            columns={columns}
                            onClick={() => getFilteredResults({getInvoicesForCVS:true})}
                            excludedColumns={["actions", "sendEmail", "print"]}
                            callback={(invoice, formattedObject) => {
                                formattedObject["Status"] = invoice.status.status;
                                formattedObject["CIS Status"] = invoice.cisStatus || "";
                            }}
                            showAmount={true}
                        />
                    </small>
                    {[1, 2, 3, 4, 5].includes(user.role.id) && (
                        <div className="col ml-2">
                            <button
                                className="btn btn-outline-primary btn-sm"
                                onClick={() => setOpenUploadInvoice(true)}
                            >
                                {t("invoice.upload")}
                            </button>
                        </div>
                    )}
                    <Dialog
                        header={t("invoice.upload")}
                        visible={openUploadInvoice}
                        onHide={() => setOpenUploadInvoice(false)}
                        style={{ width: "26vw" }}
                        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
                    >
                        <UploadInvoice
                            user={user}
                            selectedCompany={selectedCompany}
                        />
                    </Dialog>
                    <div className="actions">
                    {userCanCreate && (
                        <Link
                            to={isPos ? "/pos-transactions/create" : "/create-invoice"}
                            className="btn btn-sm btn-primary"
                        >
                            <FontAwesomeIcon icon={faPlus} className="mr-2" />
                            {t("navbar.create")}
                        </Link>
                    )}
                </div>

                    <AlertCertificate />
                    <AlertSubscription />
                </div>
                <div className="ml-md-auto mb-md-0">
                    <DateRange
                        fromDate={filters.fromDate}
                        toDate={filters.toDate}
                        onChange={(dates) => {
                            let fromDate = dates[0];
                            let toDate = dates[1];
                            if (fromDate && toDate) {
                                getFilteredResults({data:{
                                    fromDate,
                                    toDate,
                                    params: { ...filters.params },
                                }});
                            }
                            setFilters((filters) => ({
                                ...filters,
                                fromDate,
                                toDate,
                            }));
                            setDates({ fromDate, toDate })
                            // setPersistedData({ ...persistedData, fromDate, toDate });
                        }}
                    />
                </div>
                <div className="col-6 col-md-2 pr-0 d-flex align-items-center">
                    <label className="align-items-center mr-2 mb-0">BU</label>
                    <Select
                        styles={selectStyle}
                        className="w-100"
                        options={businessUnitCodes}
                        value={
                            filters.params.businessUnitCode
                                ? businessUnitCodes.find(
                                    (businessUnitCode) =>
                                        businessUnitCode.value === filters.params.businessUnitCode
                                )
                                : {
                                    label: t("invoice.businessUnitCode"),
                                    value: "",
                                }
                        }
                        isSearchable={true}
                        onChange={(businessUnitCode) => {
                            handleParamsChange(
                                null,
                                "businessUnitCode",
                                businessUnitCode.value
                            );
                            setPersistedData({
                                ...persistedData,
                                businessUnitCode: businessUnitCode.value,
                            });
                            getFilteredResults({data:{
                                ...filters,
                                params: {
                                    ...filters.params,
                                    businessUnitCode: businessUnitCode.value,
                                    tcrCode: null
                                },
                            }});
                        }}
                        name="businessUnitCode"
                    />
                </div>

            </div>
            <div className="d-none">
                {invoices.length > 0 && (
                    <ThermalPrinterInvoiceContent
                        invoice={
                            currentInvoiceIndex ? invoices[currentInvoiceIndex] : invoices[0]
                        }
                    />
                )}
                <iframe id="ifmcontentstoprint"></iframe>
            </div>
            <div className="row px-1">
                {isPos ? (
                    <ul className={`col-12 col-sm-6 col-md-3 p-2 mb-1`}>
                        {filterStatisticCard(typeOptionsPos, {
                            title: t("home.invoiceType"),
                            cardClasses: "grid-2",
                        })}
                    </ul>
                ) : (
                    <ul className={`col-12 col-sm-6 col-md-3 p-2 mb-1`}>
                        {filterStatisticCard(typeOptions, {
                            title: t("home.invoiceType"),
                            cardClasses: "grid-2",
                        })}
                    </ul>
                )}
                {isPos ? (
                    <ul className={`col-12 col-sm-6 col-md-3 p-2 mb-1`}>
                        {filterStatisticCard(statusOptionsPos, { title: t("home.status") })}
                    </ul>
                ) : (
                    <ul className={`col-12 col-sm-6 col-md-3 p-2 mb-1`}>
                        {filterStatisticCard(statusOptions, { title: t("home.status") })}
                    </ul>
                )}
                {isPos ? (
                    <ul className={`col-12 col-sm-6 col-md-3 p-2 mb-1`}>
                        {filterStatisticCard(currencyOptionsPos, {
                            title: t("invoice.currency"),
                            cardClasses: "grid-2",
                        })}
                    </ul>
                ) : (
                    <ul className={`col-12 col-sm-6 col-md-3 p-2 mb-1`}>
                        {filterStatisticCard(currencyOptions, {
                            title: t("invoice.currency"),
                            cardClasses: "grid-2",
                        })}
                    </ul>
                )}
                {isPos ? (
                    <ul className={`col-12 col-sm-6 col-md-3 p-2 mb-1`}>
                        {filterStatisticCard(otherOptionsPos, {
                            title: t("invoice.other"),
                            cardClasses: "grid-2",
                            type: "checkbox",
                        })}
                    </ul>
                ) : (
                    <ul className={`col-12 col-sm-6 col-md-3 p-2 mb-1`}>
                        {filterStatisticCard(otherOptions, {
                            title: t("invoice.other"),
                            cardClasses: "grid-4",
                            type: "checkbox",
                        })}
                    </ul>
                )}
            </div>


            <div className="card border-0 shadow">
                <div className="card-body p-0">
                    <PrimeTable
                        loading={loading}
                        showFilters={true}
                        filters={[filters, setFilters]}
                        defaultFilters={defaultFilters}
                        tableRows={[tableRows, setTableRows]}
                        persistedRowPerPage={[persistedRowPerPage, setPersistedRowPerPage]}
                        value={invoices ?? []}
                        selectedColumns={[selectedColumns, setSelectedColumns]}
                        columns={columns}
                        dataKey="id"
                        onRemoteDataChange={(remoteData) => {
                            const updatedFilters = remoteData.filters ?? filters
                            const updatedTableData = remoteData.tableRows ?? tableRows
                            let { currentPage, rowsPerPage,sortCriteria } = updatedTableData
                            sortCriteria=sortCriteria?[sortCriteria]:[]
                            getFilteredResults({ data: updatedFilters, pageSize: rowsPerPage, page: currentPage,sortCriteria })
                          }}
                        expandedRows={expandedRows}
                        onRowExpand={onRowExpand}
                        onRowCollapse={() => { setExpandedRows([]); setExpandedData([]) }}
                        rowExpansionTemplate={rowExpansionTemplate}
                        headerButtonsRight={headerButtonsRight}
                        columnDeps={[fullCompany, invoices,spinLoading,loadingPdf]}//when should the colums should be rerendered,invoices length is needed because of thermal print
                    //needs an updated index to find the invoice
                        reorderableColumns={true}
                        defaultSortCriteria={defaultSortCriteria}
                    >
                        <Column
                         filter={true}
                         showFilterMenu={false}
                         showClearButton= {false}
                         filterElement={approveMultiInovicesFilter}
                         body={approveMultiInovicesBody}  
                        //  headerStyle={{ minWidth: '40px',maxWidth:"40px" }} 
                         style={{ minWidth: "10px",maxWidth:"40px" }}
                         
                         frozen />
                        <Column
                            expander={() => true}
                            style={{ minWidth: "70px",maxWidth:"70px" }}
                            header=""
                            field="expansion"
                            frozen={true}
                        />
                    </PrimeTable>
                </div>
            </div>
            {pdfEmbed}
        </>
    ) : (
        <Loader />
    );
};

export default CustomInvoicesNew;
