import React, { useState, useRef, useEffect, useMemo, useCallback } from "react";
import Table from "../Table";
import "../../design/assets-rangeDate/styles/calendar.scss";
import func, { swalAlert } from "../../services/functions";
import mainAxios from "../../services/mainAxios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faDownload,
    faSyncAlt,
} from "@fortawesome/free-solid-svg-icons";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import Loader from "../Layout/Loader";
import { useAppContext, usePersistedState } from "../../AppContext";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import selectStyle from "../../design/selectStyle";
import AddOrRemoveColumns from "../global/AddOrRemoveColumns";
import generatePurchasesColumns from "./columns/generatePurchasesColumns";
import { useHistory } from "react-router-dom";
import "../../design/custom.scss";
import { purchaseAction } from "./columns/PurchaseAction";
import PopOver from "../global/PopOver";
import ExportCsv from "../global/ExportCsv";
import DateRange from "../global/DateRange";
import AlertCertificate from "../global/AlertCertificate";
import AlertSubscription from "../global/AlertSubscription";
import { Column } from "primereact/column";
import PrimeTable, { onColReorder } from "../PrimeTable/PrimeTable";
import { Checkbox } from "primereact/checkbox";
import CellInput from "../PrimeTable/CellInput";
import ExportCsvPrime from "../../components/global/ExportCsvPrime"
import { globalFilterStatisticCard } from "../global/StatisticsCards";
import CisMessage from "../global/CisMessage";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { defaultTableRows } from "../../services/data";
import ExportCsvMultiple from "../global/ExporCsvMultiple";
import { Circles, Oval, Preloader, ThreeDots } from "react-preloader-icon";
import { DisableLayout } from "../Layout/DisableLayout";
import useShowInvoicePdf from "../global/useShowInvoicePdf";

const moment = require("moment");
const PurchasesNew = () => {
    const { t,i18n } = useTranslation("translations");


    const {
        user: [user],
        selectedCompany: [selectedCompany],
        purchasesColumns: [purchasesColumns, setPurchasesColumns],
        company: [fullCompany],
        // dates: [dates, setDates],
        purchaseDates: [purchaseDates, setPurchaseDates],
        unitsOfMeasure,
        setDisabledLayout
        

    } = useAppContext();

    const company = user.companiesRights.find(
        (company) => company.id === parseInt(selectedCompany)
    );
    const [statistics, setStatistics] = useState(null);
    const businessUnitCodes = React.useMemo(() => {
        return [{
            label: t("invoice.businessUnitCode"),
            value: null,
        }].concat(company.branches.map((branch) => {
            return {
                label: branch.businUnitCode + " | " + branch.sellerName,
                value: branch.businUnitCode,
            };
        }))
    }, [selectedCompany])

    const [selectedRows, setSelectedRows] = useState([]);
    const [actionLoading, setActionLoading] = useState(false)
    const [refreshGrid, setRefreshGrid] = useState(false)
    const [persistedData, setPersistedData] = usePersistedState(
        null,
        "sessionData"
    );
    const [persistedRowPerPage, setPersistedRowPerPage,] = usePersistedState(
        10,
        "rowsPerPage"
    );
    const businessUnitSelected = businessUnitCodes?.some(
        (branch) => branch?.value === persistedData?.businessUnitCode
    )
        ? persistedData?.businessUnitCode
        : "";

    const filterParams = {
        dataFrom: "DB",
        search: "einvoice",
        internalStatus: [],
        purchaseUnit: [],
        priority: [],
        currency: [],
        checkUnknownBusiness: false,
        purchaseStatus: [],
    };
    const defaultFilters = {
        params: { ...filterParams },
        fromDate: purchaseDates.fromDate,
        toDate: purchaseDates.toDate,
    };
    const [filters, setFilters] = useState(defaultFilters);
    const [invoices, setInvoices] = useState(null);
    const [pdfToDisplay, setPdfToDisplay] = useState(null);
    const [dataFrom, setDataFrom] = useState("DOC");
    const [loadingSupplierName, setLoadingSupplierName] = useState({
        loading: false,
        id: null,
    });
    const [selectedPurchaseUnit,setSelectedPurchaseUnit]=useState()


    const defaultExpand = {
        invoiceItems: [],
        expandedRows: null,
        loadingInvoiceItems: false,
        expandedInvoiceRows: [],
        expandedInvoiceData: null,
        loadingInvoiceData: false
      }
      const [expanded, setExpanded] = useState(defaultExpand)

    const tcrList = React.useCallback((businessUnitCode, showSelectValue = true) => {
        let buCode = businessUnitCode || businessUnitSelected;

        company.branches.reduce((acc, branch) => branch?.tcrTypes?.length > 0 ? acc.concat(branch.tcrTypes) : acc, [])
        let branch = company.branches.find(branch => branch.businUnitCode == buCode)
        let tcrList = branch?.tcrTypes?.filter((tcrType) => tcrType?.allowPurchase).map((tcr) => {
            let sellerName = branch.sellerName
            return {
                label: tcr.tcrCode + "-" + sellerName,
                value: tcr.tcrCode,
            }
        }) ?? []

        return  tcrList
    }, [selectedCompany, persistedData?.businessUnitCode])

    const allTcr = React.useMemo(() => {

        let tcrList = []
        company.branches
            .reduce((acc, branch) => branch?.tcrTypes?.length > 0 ? acc.concat(branch.tcrTypes) : acc, [])
            .forEach(tcr => {
                if (tcr?.allowPurchase) {
                    let sellerName = company?.branches?.find((branch) => branch?.businUnitCode == tcr?.businUnitCode)?.sellerName ?? ""
                    tcrList.push({ label: tcr.tcrCode + " - " + sellerName, value: tcr.tcrCode })
                }
            })
        return tcrList
    }, [selectedCompany])



    const [uIType, setUIType] = useState({
        value: "eic",
        label: "EIC",
        uidValue: "",
    });

    const [loading, setLoading] = useState(false);

    const pdfContainer = useRef();

    const defaultSortCriteria={
        field: "recordDateTime",
        direction: "DESC"
    }

    const [tableRows, setTableRows] = useState({
        ...defaultTableRows,
        rowsPerPage:persistedRowPerPage,
        sortCriteria:defaultSortCriteria
    });
    const [invoicesForCSV, setInvoicesForCSV] = useState([]);

    const getFilteredResults = (
        {
            data,
            pageSize = (persistedRowPerPage?? defaultTableRows.rowsPerPage),
            page = defaultTableRows.currentPage,
            dataFromCis = false,
            getInvoicesForCVS = false,
            buReset = false,
            approvedInvoices,
            status,
            sortCriteria = [ defaultSortCriteria ],
            returnData=false
        }
    ) => {
        const filterData = data ? { ...data } : { ...filters };
        if (!getInvoicesForCVS && !approvedInvoices && !returnData) setLoading(true);
        if (approvedInvoices) setActionLoading(true)
        if (invoicesForCSV.length > 0) setInvoicesForCSV([]);
        if (approvedInvoices) setActionLoading(true)
        if (!data) {
            filterData.params = {
                ...filterData.params,
                dataFrom: dataFromCis ? "CIS" : "DB",
                checkUnknownBusiness: dataFromCis
                    ? filterData.params.checkUnknownBusiness
                    : false,
            };

            setDataFrom(dataFromCis ? "CIS" : "DB");

            if (uIType?.uidValue) {
                let val;
                if (dataFromCis) {
                    val = uIType.uidValue;
                    if (
                        uIType.value === "eic" &&
                        !new RegExp(
                            /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[1-5][0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}/
                        ).test(val)
                    ) {
                        toast.warning(
                            "Incorrect Pattern for type " + uIType.value.toUpperCase(),
                            {
                                containerId: "all_toast",
                            }
                        );
                    } else {
                        filterData.params = {
                            ...filterData.params,
                            [uIType.value]: val,
                        };
                    }
                } else {
                    //concat with <L> in case of DB
                    val = uIType.uidValue;
                    filterData.params = {
                        ...filterData.params,
                        [uIType.value]: val,
                    };
                }
            }
        }
        let purchaseUnit = ""
        if (filterData.params.purchaseUnit.length == 0 && buReset) {
            purchaseUnit = ""
        } else if (filterData.params.purchaseUnit.length == 0) {
            purchaseUnit = tcrList(null, false).map(tcr => tcr.value).join(",")
        } else {
            purchaseUnit = filterData.params.purchaseUnit.join(",")
        }

        const endpoint = approvedInvoices ? "endpoint/v2/changePurchaseInvoicesStatusInGroup" : "endpoint/v2/getMyPurchases"
        let reqParams = {}
        Object.keys(filterData.params).forEach(param => {
            if (filterData.params[param] != "" && param != "purchaseUnit") {
                if (param === "priority" || param === "currency" || param === "internalStatus" || param === "purchaseStatus") {
                    reqParams[param] = filterData.params[param].join(",");
                    // } else if (param === "checkUnknownBusiness"){
                    //   reqParams.checkUnknownBusiness = filterParams.checkUnknownBusiness
                } else {
                    reqParams[param] = filterData.params[param]
                }
            } else if (param === "checkUnknownBusiness") {
                reqParams.checkUnknownBusiness = filterParams.checkUnknownBusiness
            }
        })

        if (purchaseUnit !== "") {
            reqParams.purchaseUnit = purchaseUnit;
        }

        return mainAxios
            .post(endpoint, {
                ...filterData,
                fromDate:purchaseDates.fromDate,
                toDate:purchaseDates.toDate,
                value: approvedInvoices ? approvedInvoices?.join(",") : null,
                ...(status ? { status } : {}),
                params: {
                    ...reqParams
                },
                pagination: getInvoicesForCVS
                    ? null
                    : {
                        pageSize: pageSize,
                        pageNumber: page,
                    },
                nuis: user.companiesRights.find(
                    (company) => company.id === parseInt(selectedCompany)
                )?.nuis,
                sortCriteria
            })
            .then((res) => {
                if (!getInvoicesForCVS && !approvedInvoices && !returnData ) setLoading(false);
                if (approvedInvoices) setActionLoading(false)
                if (res?.data?.status) {
                    if(returnData) return res.data.content
                    if (getInvoicesForCVS) {
                        setInvoicesForCSV(res.data.content);
                    } else {
                        setInvoices(res.data.content);
                        setTableRows((tableRows)=>({
                            ...tableRows,
                            totalRows: res?.data?.totalSize,
                          }));
                    }


                    return true;
                }
                return false;

            })
            .catch((err) => {
                if (!getInvoicesForCVS && !approvedInvoices && !returnData) setLoading(false);
                if (approvedInvoices) setActionLoading(false)

                return false;
            });
    };
    
    const downloadByPeriod = (
        {
            data,
            pageSize = (persistedRowPerPage?? defaultTableRows.rowsPerPage),
            page = defaultTableRows.currentPage,
            dataFromCis = false,
            getInvoicesForCVS = false,
            buReset = false,
            approvedInvoices,
            status,
            sortCriteria = [ defaultSortCriteria ],
            returnData=false
        }
    ) => {
        const filterData = data ? { ...data } : { ...filters };
        setRefreshGrid(true)
        setDisabledLayout(true)
        if (approvedInvoices) setActionLoading(true)
        if (invoicesForCSV.length > 0) setInvoicesForCSV([]);
        if (approvedInvoices) setActionLoading(true)
        if (!data) {
            filterData.params = {
                ...filterData.params,
                dataFrom: dataFromCis ? "CIS" : "DB",
                checkUnknownBusiness: dataFromCis
                    ? filterData.params.checkUnknownBusiness
                    : false,
            };

            setDataFrom(dataFromCis ? "CIS" : "DB");

            if (uIType?.uidValue) {
                let val;
                if (dataFromCis) {
                    val = uIType.uidValue;
                    if (
                        uIType.value === "eic" &&
                        !new RegExp(
                            /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[1-5][0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}/
                        ).test(val)
                    ) {
                        toast.warning(
                            "Incorrect Pattern for type " + uIType.value.toUpperCase(),
                            {
                                containerId: "all_toast",
                            }
                        );
                    } else {
                        filterData.params = {
                            ...filterData.params,
                            [uIType.value]: val,
                        };
                    }
                } else {
                    //concat with <L> in case of DB
                    val = uIType.uidValue;
                    filterData.params = {
                        ...filterData.params,
                        [uIType.value]: val,
                    };
                }
            }
        }
        let purchaseUnit = ""
        if (filterData.params.purchaseUnit.length == 0 && buReset) {
            purchaseUnit = ""
        } else if (filterData.params.purchaseUnit.length == 0) {
            purchaseUnit = tcrList(null, false).map(tcr => tcr.value).join(",")
        } else {
            purchaseUnit = filterData.params.purchaseUnit.join(",")
        }

        const endpoint = "apiEndpoint/apiExtractAllPurchaseInvoicesWithDates"
        let reqParams = {}
        Object.keys(filterData.params).forEach(param => {
            if (filterData.params[param] != "" && param != "purchaseUnit") {
                if (param === "priority" || param === "currency" || param === "internalStatus" || param === "purchaseStatus") {
                    reqParams[param] = filterData.params[param].join(",");
                    // } else if (param === "checkUnknownBusiness"){
                    //   reqParams.checkUnknownBusiness = filterParams.checkUnknownBusiness
                } else {
                    reqParams[param] = filterData.params[param]
                }
            } else if (param === "checkUnknownBusiness") {
                reqParams.checkUnknownBusiness = filterParams.checkUnknownBusiness
            }
        })

        if (purchaseUnit !== "") {
            reqParams.purchaseUnit = purchaseUnit;
        }

        return mainAxios
            .post(endpoint, {
                ...filterData,
                value: approvedInvoices ? approvedInvoices?.join(",") : null,
                ...(status ? { status } : {}),
                params: {
                    ...reqParams
                },
                pagination: getInvoicesForCVS
                    ? null
                    : {
                        pageSize: pageSize,
                        pageNumber: page,
                    },
                nuis: user.companiesRights.find(
                    (company) => company.id === parseInt(selectedCompany)
                )?.nuis,
                sortCriteria
            })
            .then((res) => {
                if(res.data.status){
                    setRefreshGrid((state)=>!state)
                    toast.success(`${res.data.message}`, {
                        containerId: "all_toast",
                      });
                }
              setDisabledLayout(false)
            })
            .catch((err) => {
                if (!getInvoicesForCVS && !approvedInvoices && !returnData) setLoading(false);
                if (approvedInvoices) setActionLoading(false)
                if (approvedInvoices) setDisabledLayout(false)

                return false;
            });
    };

    useEffect(() => {
        if (!(purchaseDates?.fromDate && purchaseDates?.toDate)) {
            return;
        }
        let endpoint = persistedData?.businessUnitCode
            ? "/endpoint/v2/reports/mypurchase/branch/aggregate"
            : "/endpoint/v2/reports/mypurchase/company/aggregate";
        mainAxios
            .post(endpoint, {
                object: "GetMyPurchaseCashDepositStatisticsAnalytics",
                fromDate: moment(purchaseDates?.fromDate).format("YYYY-MM-DD"),
                toDate: moment(purchaseDates?.toDate).format("YYYY-MM-DD"),
                ...(persistedData?.businessUnitCode
                    ? { params: { businessUnitCode: persistedData?.businessUnitCode } }
                    : {}),
            })
            .then((res) => {
                if (res?.data?.status) {
                    let getStatistics = calculateStatistics(res.data.content);
                    setStatistics(getStatistics);
                }
            });
    }, [purchaseDates?.fromDate, purchaseDates?.toDate, purchaseDates?.params?.purchaseUnit, purchaseDates.reloadData ]);

    const calculateStatistics = (data) => {
        if (data?.length > 0) {
            const { id, businessUnitCode, company, recordDate, ...rest } = data[0];
            let stats = {};
            Object.keys(rest).forEach((key) => {
                stats[key] = data.reduce((sum, tcrStat) => sum + tcrStat[key], 0);
            });
            return stats;
        }
        return [];
    };

    useEffect(() => {
        if (!(purchaseDates?.fromDate && purchaseDates?.toDate)) {
            return;
        }
        getFilteredResults({ data: defaultFilters });
    }, [refreshGrid,purchaseDates.reloadData ,purchaseDates?.fromDate, purchaseDates?.toDate]);








  const unitofMeasureBody = useCallback((row)=>{const uom = unitsOfMeasure.find(uom=>uom.englishName===row.unitOfMeasure) ; if(uom){ return i18n.language == "alb" ?   uom?.albanianName: uom?.englishName} return row?.unitOfMeasure ?? ""},[unitsOfMeasure,i18n])

    const expandedColumns = [
        {
          field: "",
          header: "",
          style: { minWidth: 40,maxWidth:40 },
        },
        {
          field: "itemCode",
          header: t("books.itemCode"),
          style: { minWidth: 170,maxWidth:170 },
          sortable: true,
        },
        {
          field: "itemName",
          header: t("books.itemName"),
          style: { minWidth: 170,maxWidth:170 },
          sortable: true,
        },
        {
          field: "unitOfMeasure",
          header: t("books.unitOfMeasure"),
          style: { minWidth: 120,maxWidth:120 },
          body:unitofMeasureBody
        },
        {
          field: "itemQuantity",
          header: t("books.itemQuantity"),
          style: { minWidth: 90,maxWidth:90 },
        },
        {
          field: "unitPriceBefore",
          header: t("books.unitPriceBefore"),
          style: { minWidth: 150,maxWidth:150 },
          body: (row) =>
            func.getFormattedMoneyValue(row?.unitPriceBefore ?? 0),
          align: "right"
        },
        {
          field: "unitPriceAfter",
          header: t("books.unitPriceAfter"),
          style: { minWidth: 150,maxWidth:150 },
          body: (row) =>
            func.getFormattedMoneyValue(row?.unitPriceAfter ?? 0),
          align: "right"
        },
        {
          field: "vatRate",
          header: t("books.vatRate"),
          style: { minWidth: 100,maxWidth:100 },
          body: (row) =>
            func.getFormattedMoneyValue(row?.vatRate ?? 0),
          align: "right"
        },
        {
          field: "linePriceBefore",
          header: t("books.linePriceBefore"),
          style: { minWidth: 150,maxWidth:150 },
          body: (row) =>
            func.getFormattedMoneyValue(row?.linePriceBefore ?? 0),
          align: "right"
        },
        {
          field: "vatAmount",
          header: t("books.vatAmount"),
          style: { minWidth: 150,maxWidth:150 },
          body: (row) =>
            func.getFormattedMoneyValue(row?.vatAmount ?? 0),
          align: "right"
        },
        {
          field: "",
          header: "",
          style: { width: 2500 },
        },

      ];

      const rowExpansionTemplate = () => {
        const expandedInvoiceData = expanded?.expandedInvoiceData?.data
        if (expanded?.loadingInvoiceData || expanded?.loadingInvoiceItems) return <div style={{ width: "100vw" }}><Loader /> </div>

        return (
        <div className="d-flex flex-column ml-3 mt-3">
            {expandedInvoiceData && <div className="mb-2">
            <h4>{expanded?.expandedInvoiceData?.type == "Corrective" ? t("queue.correctedInvoice") : t("queue.correctiveInvoice")}</h4>
          <PrimeTable
            loading={expanded?.loadingInvoiceData}
            value={expandedInvoiceData ?? []}
            columns={columns}
            showFilters={false}
            paginator={false}
            filterDisplay={false}
            responsiveLayout="scroll"
            selectedColumns={[selectedColumns, setSelectedColumns]}
            showHeader={false}

          >
            <Column style={{width:40}}/>
          </PrimeTable>
        </div>}
        <div className="mb-5" style={{ width: "100vw" }}>
        <h4>Invoice Items</h4>
          <PrimeTable
            value={expanded.invoiceItems}
            loading={expanded?.loadingInvoiceItems}
            columns={expandedColumns}
            showFilters={false}
            paginator={false}
            filterDisplay={false}
            responsiveLayout="scroll"
          />
        </div>
        </div>

        );
      };
      const invoiceItemsOnExpand = (event) => {
        setExpanded((state) => ({ ...state, loadingInvoiceItems: true, expandedRows: [event.data] }))
        mainAxios
          .post("apiEndpoint/search", {
            object: "GetPurchaseInvoiceItems",
            params: {
                invoiceId: event?.data?.id?.toString(),
            },
          })
          .then((res) => {
            setExpanded(state => ({ ...state, loadingInvoiceItems: false }))
            if (res.data.status) {
                setExpanded((state) => ({ ...state, invoiceItems: res?.data?.content }))
            }
          })
        .catch(err => setExpanded(state => ({ ...state, loadingInvoiceItems: false })))

      };
      const correctiveOnExpand = async (event) => {
        const data = event.data
        const params = {}
        let type = null
        if (data.profileId=="P1") {
            params.iicRef = data.iic
            type = "Corrected"
          }
        else if (data.iicRef) {
          params.iic = data.iicRef
          type = "Corrective"
        }

        if (!params.iic && data.profileId!=="P1") return
        setExpanded((state) => ({ ...defaultExpand, loadingInvoiceData: true }))
        const fromDate=data?.dateRef ?moment(data.dateRef).subtract(3,"days").toISOString(): defaultFilters.fromDate
        const toDate=data?.dateRef ?moment(data.dateRef).add(3,"days").toISOString(): defaultFilters.toDate

        const invoices = await getFilteredResults({ data: {fromDate,toDate, params: { ...defaultFilters.params, ...params } }, page: 1, returnData: true })
        if (invoices?.length > 0) {
          setExpanded((state) => ({ ...state, loadingInvoiceData: false, expandedInvoiceData: { data: invoices,type  } }))
        } else {
          setExpanded((state) => ({ ...state, loadingInvoiceData: false, expandedInvoiceData: null })); return
        }
      }

      const onRowExpand = (event) => {
        correctiveOnExpand(event)
        invoiceItemsOnExpand(event)
      };

      const onRowCollapse = (event) => {
        setExpanded(defaultExpand);
    };
    const internalStatusOptions = [
        {
            key: "internalStatus",
            multiSelect: true,
            label: "DRAFT",
            count: statistics?.draftStatusInvoices,
            value: "Draft",
        },

        {
            key: "internalStatus",
            multiSelect: true,
            count: statistics?.approvalStatusInvoices,
            label: "APPROVAL",
            value: "Approval",
        },
        {
            key: "internalStatus",
            count: statistics?.financeStatusInvoices,
            multiSelect: true,
            label: t("purchases.finance").toUpperCase(),
            value: "Finance",
        },

        {
            key: "internalStatus",
            multiSelect: true,
            count: statistics?.refusedStatusInvoices,
            label: t("status.REFUSED").toUpperCase(),
            value: "Refused",
        },
        {
            key: "internalStatus",
            multiSelect: true,
            count: statistics?.acceptedStatusInvoices,
            label: t("queue.accepted").toUpperCase(),
            value: "Accepted",
        },
    ];

    const cisStatusOptions = [
        {
            key: "purchaseStatus",
            count: statistics?.cisDeliveredStatusInvoices,
            multiSelect: true,
            label: func.capitalizeFirstLetter(t("status.DELIVERED")),
            value: "DELIVERED",
        },
        {
            key: "purchaseStatus",
            count: statistics?.cisAcceptedStatusInvoices,
            multiSelect: true,
            label: t("queue.accepted"),
            value: "ACCEPTED",
        },
        {
            key: "purchaseStatus",
            multiSelect: true,
            count: statistics?.cisRejectedStatusInvoices,
            label: t("purchases.rejected"),
            value: "REFUSED",
        },

        {
            key: "purchaseStatus",
            multiSelect: true,
            count: statistics?.cisPartiallyPaidStatusInvoices,
            label: func.capitalizeFirstLetter(t("status.PARTIALLY_PAID")),
            value: "PARTIALLY_PAID",
        },
        {
            key: "purchaseStatus",
            count: statistics?.cisPaidStatusInvoices,
            multiSelect: true,
            label: func.capitalizeFirstLetter(t("status.PAID")),
            value: "PAID",
        },
    ];

    const amounts = [
        {
            key: "amounts",
            label: t("invoice.approvals"),
            value: "approvals",
        },
        {
            key: "amounts",
            label: t("invoice.rejections"),
            value: "rejections",
        },
    ];

    const priorityOptions = [
        {
            key: "priority",
            count: statistics?.lowPriorityInvoices,
            multiSelect: true,
            label: t("invoice.low"),
            value: "Low",
        },
        {
            key: "priority",
            count: statistics?.mediumPriorityInvoices,
            multiSelect: true,
            label: t("invoice.medium"),
            value: "Medium",
        },
        {
            key: "priority",
            count: statistics?.highPriorityInvoices,
            multiSelect: true,
            label: t("invoice.high"),
            value: "High",
        },
    ];

    const otherOptions = [
        {
            key: "downloaded",
            count: statistics?.apiDownloaded,
            label: t("invoice.apiDownloaded"),
            value: "true",
        },
        {
            key: "profileId",
            count: statistics?.selfInvoiceNo,
            label: t("invoice.selfInvoice"),
            value: "P12",
          },
        {
            key: "profileId",
            count: statistics?.correctiveInvoice,
            label: t("invoice.correctiveInvoice"),
            value: "P9,P10",
          },
    ]

    const uIOptions = [
        { value: "eic", label: "EIC" },
        { value: "iic", label: t("invoice.IIC") },
        { value: "fic", label: t("invoice.FIC"), },
        { value: "docNumber", label: t("invoice.DocNumber") },
        { value: "sellerTin", label: t("invoice.sellerNuis") },
    ];

    const currencyOptions = [
        {
            key: "currency",
            count: statistics?.allCurrencyInvoices,
            multiSelect: true,
            label: "Lek",
            value: "ALL",
        },
        {
            key: "currency",
            multiSelect: true,
            count: statistics?.usdCurrencyInvoices,
            label: "USD",
            value: "USD",
        },
        {
            key: "currency",
            count: statistics?.eurCurrencyInvoices,
            multiSelect: true,
            label: "Euro",
            value: "EUR",
        },
    ];


    const searchSupplierName = (id, eic, sellerTin) => {
        let index = invoices?.findIndex((invoice) => invoice?.id == id);
        if (index >= 0) setLoadingSupplierName({ loading: true, id });
        mainAxios
            .post("endpoint/v2/checkSupplier", {
                params: { eic },
                value: sellerTin,
                nuis: user.companiesRights.find(
                    (company) => company.id === parseInt(selectedCompany)
                )?.nuis,
            })
            .then((res) => {
                setLoadingSupplierName({ loading: false, index });
                if (index >= 0) setLoadingSupplierName({ loading: false, id });
                if (res?.data?.content?.length > 0 && index >= 0) {
                    setInvoices((invoices) => {
                        return [
                            ...invoices.slice(0, index),
                            {
                                ...invoices[index],
                                albanianBusinesses: {
                                    ...res.data.content[0],
                                },
                            },
                            ...invoices.slice(index + 1),
                        ];
                    });
                }
            });
    };


    const approvedPurchases = invoices?.filter(
        (invoice) => invoice?.internalStatus === "Approval"
    );

    let ApprovedPurchaseAmount = 0;

    if (approvedPurchases) {
        approvedPurchases.forEach((invoice) => {
            ApprovedPurchaseAmount = ApprovedPurchaseAmount + invoice.amount;
        });
    }

    const refusedPurchases = invoices?.filter(
        (invoice) => invoice?.internalStatus === "Refused"
    );

    let RefusedPurchaseAmount = 0;

    if (refusedPurchases) {
        refusedPurchases.forEach((invoice) => {
            RefusedPurchaseAmount = RefusedPurchaseAmount + invoice.amount;
        });
    }

    const handleParamsChange = (e, label, val) => {
        const key = e ? e.target.name : label;
        const value = e ? e.target.value : val;
        if (uIOptions?.map((op) => op.value).includes(key)) {
            setUIType({
                value: key,
                label: uIOptions?.find((op) => op.value == key)?.label,
                uidValue: value,
            });
        } else {
            setFilters((filters) => ({
                ...filters,
                params: {
                    ...filters.params,
                    [key]: value,
                },
            }));
        }
    };

  const loadingIdKey="fic"
  const { showInvoicePdf,pdfEmbed,loadingPdf } = useShowInvoicePdf({loadingIdKey})


    const columns = generatePurchasesColumns({
        purchasesColumns,
        t,
        i18n,
        loadingSupplierName,
        dataFrom,
        loadingPdf,
        loadingIdKey,
        showInvoicePdf,
        searchSupplierName,
        purchaseRole:user.purchaseRole,
        allTcr,
        cisStatusOptions,
        internalStatusOptions,
        priorityOptions,
        currencyOptions,
        tcrList,
        filters,
        handleParamsChange,
        getFilteredResults
    });

    const [selectedColumns, setSelectedColumns] = usePersistedState(
        columns.map((col) => ({field:col.field,show:!!col.default,...(col?.persisted?{persisted:true}:{})})),
        "purchasesColumns4"
    );
    const expandableColumns = [
        {
            header: "Action",
            field: "workflowAction",
            body: (invoice) => {
                if (invoice?.workflowAction?.name) {
                    let purchaseActionProps = purchaseAction(invoice?.workflowAction.name);
                    return (
                        <div className={`badge badge-${purchaseActionProps.color}`}>
                            {purchaseActionProps.name}
                        </div>
                    );
                }
                return "";
            },
        },
        {
            header: "Purchase Status",
            field: "workflowStatus",
            body: (invoice) => invoice?.workflowStatus?.name ?? "",
        },
        {
            header: "Comment",
            field: "comment",
            body: (invoice) =>
                invoice?.comment?.length > 85 ? (
                    <CellInput value={invoice?.comment} width={85}/>
                ) : (invoice?.comment ?? ""),
        },
        {
            header: "Date",
            field: "recordDate",
            body: (invoice) => invoice?.recordDate?moment(invoice?.recordDate).format("DD/MM/YYYY HH:mm"):"",
            sortable: true,
        },
        {
            header: "User",
            field: "recordUser",
        },
    ];

    const filterStatisticCard=(typeOptions,props)=>globalFilterStatisticCard(typeOptions,{...props,handleParamsChange,getFilteredResults,user,filters})



    const headerLeft = (
        <>
            {[8, 13, 14].includes(user?.purchaseRole?.id) && (
                <div className="d-flex justify-content-end align-items-center ml-1 mt-4">
                        <PopOver
                            customStyle={{
                                borderRadius: "40%",
                                width: "20px",
                                height: "20px",
                                transform: "scale(0.80)",
                                backgroundColor: "#343a40",
                                color: "white",
                                marginBottom:"0px !important"
                            }}
                            title={t("purchases.checkSuppliers")}
                        />
                    <div className="custom-control custom-checkbox ">
                        <input
                            type="checkbox"
                            name="checkUnknownBusiness"
                            id="checkUnknownBusiness"
                            className="custom-control-input"
                            checked={filters.params.checkUnknownBusiness}
                            onChange={(e) =>
                                handleParamsChange(
                                    "",
                                    "checkUnknownBusiness",
                                    e.target.checked
                                )
                            }
                        />
                        <label
                            htmlFor="checkUnknownBusiness"
                            className="custom-control-label mr-4"
                            style={{ paddingLeft: "0.5em", paddingTop: "0.2em" }}
                        >
                            {t("purchases.saveunknown")}
                        </label>
                    </div>

                    <div
                        className="btn btn-sm btn-outline-primary rounded-pill"
                        disabled={loading}
                        onClick={(e) => {
                            getFilteredResults({ page: tableRows.currentPage, pageSize: 10, dataFromCis: true });
                        }}
                    >
                        <FontAwesomeIcon icon={faSyncAlt} className="mr-2" />
                        {t("purchases.checkNew")}
                    </div>
                    {
                        user?.role?.id == 1 && 
                            <DisableLayout>

                            <button
                            className="btn btn-sm btn-primary rounded-pill ml-2"
                            disabled={refreshGrid}
                            onClick={(e) => {
                                downloadByPeriod({ page: tableRows.currentPage, pageSize: 10, dataFromCis: true });
                            }}
                            >
                            
                       <div className="d-flex align-items-center gap-1">

                        { refreshGrid ?
                            <Preloader use={Oval}
                            size={20}
                            strokeWidth={2}
                            strokeColor="#ffff"
                            // duration={1000}
                             />
                             :
                             <FontAwesomeIcon  icon={faDownload} className="mr-2" />
                             }
                        {t("purchases.downloadByPeriod")}
                        </div>

                    </button>
                    </DisableLayout>

                    }
                </div>
            )}
        </>
    )

    const clearAction=(
        <button 
        className={`btn btn-outline-primary mr-3 ${fullCompany?.workflow?.id !== 1 ? "mt-4" : ""}`}
        disabled={actionLoading}
        onClick={async () => {
            if (actionLoading) return;
            setSelectedRows([])
        }}>
        {actionLoading ? <Loader size="xs" /> : t("common.clear")}
        </button>
    )
    const [openApprovePurchaseWorkflow,setOpenApprovePurchaseWorkflow]=useState(false)
    const purchaseUnitOptions=[{label:"Select",value:null}].concat(allTcr)
    const saveForApprovalPurchase=({status, workflowStatus,action})=>{
        setActionLoading(true)

        mainAxios.post("endpoint/v2/saveMyPurchaseInvoiceInfoGroup",{
            attachements: [],
            comment: "",
            status,
            workflowStatus,
            action,
            ids: [...selectedRows],
            purchaseUnit:selectedPurchaseUnit,
            nuis:user.companiesRights.find(
                (company) => company.id === parseInt(selectedCompany)
              )?.nuis
        }).then(res=>{
            setActionLoading(false)
            if(res.data.status){
                setSelectedRows([])
                toast.success(t(["common.actionSuccessfully"]), {
                    containerId: "all_toast",
                })
                getFilteredResults({})
                setOpenApprovePurchaseWorkflow(false)
            }
        })
    }

    const  
    approveMultiInovicesFilterWorkflow1 = useMemo(() => {
        const invoicesforApproval = invoices?.filter(invoice => invoice?.purchaseStatus === "DELIVERED" && invoice.eic && invoice?.extracted) ?? []
        
        if (invoicesforApproval?.length == 0 || user?.purchaseRole?.id!=13) return <></>
        return <Checkbox
            onChange={
                (e) => {
                    if (e.checked) {
                        const _selectedRows = []
                        invoicesforApproval.forEach(invoice => {
                                _selectedRows.push(invoice?.eic)
                        })
                        setSelectedRows(_selectedRows)
                    } else {
                        setSelectedRows([])
                    }
                }
            }
            checked={selectedRows.length == invoicesforApproval?.length && selectedRows.length!=0}
        />
    }, [invoices, selectedRows,fullCompany?.workflow,user?.purchaseRole])


    const approvePurchasesWorkflow1 = (
        selectedRows.length > 0 && <div className="d-flex align-items-center mt-4">
            <button className="btn btn-outline-primary mr-3"
                disabled={actionLoading}
                onClick={async () => {
                    if (actionLoading) return;
                    
                    const sentToCisBodyApprove={
                        action: "Approve CIS",
                        status: "DELIVERED",
                        workflowStatus: "Accepted"
                    }

                    let res = await swalAlert({ title: t("purchases.approveAllWarning"),t })
                    if (res) {
                        saveForApprovalPurchase(sentToCisBodyApprove)
                    }
                }}>
                {actionLoading ? <Loader size="xs" /> : t(["purchases.approveAll"])}
            </button>
            <button className="btn btn-outline-primary mr-3"
                disabled={actionLoading}
                onClick={async () => {
                    if (actionLoading) return;
                    let res = await swalAlert({ title: t("purchases.rejectAllWarning"),t })
                    if (res) {
                        const sentToCisBodyReject={
                            action: "Reject CIS",
                            status: "DELIVERED",
                            workflowStatus: "Refused"
                        }
                        saveForApprovalPurchase(sentToCisBodyReject)
                    }
                }}>
                {actionLoading ? <Loader size="xs" /> : t(["purchases.rejectAll"])}
            </button>
            {clearAction}
        </div>
    )

    const approveWorkflow1Body = (invoice) => {

        console.log({first:invoice?.purchaseStatus !== "DELIVERED" || !invoice.eic  || user?.purchaseRole?.id!=13,test2:invoice?.purchaseStatus !== "DELIVERED",invoice,user})

        if (invoice?.purchaseStatus !== "DELIVERED" || !invoice.eic  || user?.purchaseRole?.id!=13 || !invoice?.extracted) return <></>
        return <Checkbox
            onChange={
                (e) => {
                    setSelectedRows((state) => {
                        const _state = [...state]
                        const checkedValueIndex = _state.findIndex(sRow => sRow == invoice?.id)
                        if (checkedValueIndex >= 0) {
                            _state.splice(checkedValueIndex, 1)
                            return _state
                        }
                        _state.push(invoice.id)
                        return _state
                    })
                }
            }
            checked={!!selectedRows.find(sRow => sRow == invoice?.id)}
        />
    }


    const approveMultiInovicesFilterWorkflowOther = useMemo(() => {
        const invoicesforApproval = invoices?.filter(invoice => invoice?.internalStatus == "Draft" && invoice.purchaseStatus=="DELIVERED" && invoice.id && invoice.extracted && [8].includes(user?.purchaseRole?.id)) ?? []
        
        if (invoicesforApproval?.length == 0) return <></>
        return <Checkbox
            onChange={
                (e) => {
                    if (e.checked) {
                        const _selectedRows = []
                        invoicesforApproval.forEach(invoice => {
                                _selectedRows.push(invoice?.id)
                        })
                        setSelectedRows(_selectedRows)
                    } else {
                        setSelectedRows([])
                    }
                }
            }
            checked={selectedRows.length == invoicesforApproval?.length && selectedRows.length!=0}
        />
    }, [invoices, selectedRows,fullCompany?.workflow,user?.purchaseRole])

    const approvePurchaseWorkflowOther=(
        selectedRows.length > 0 && 
        <>
            <div className="d-flex align-items-center">
                            <button className="btn btn-outline-primary mr-3 mt-4"
                    disabled={actionLoading}
                    onClick={async () => {
                        if (actionLoading) return;
                        setOpenApprovePurchaseWorkflow(true)
                    }}>
                    {actionLoading ? <Loader size="xs" /> : t(["purchases.submitForApproval"])}
                </button>
                {clearAction}
            </div>
            <Dialog
                header={t("purchases.submitAllForApprovalWarning")}
                visible={openApprovePurchaseWorkflow}
                onHide={() => setOpenApprovePurchaseWorkflow(false)}
                style={{ width: "590px" }}
                breakpoints={{ "960px": "75vw", "641px": "100vw" }}
            >
                <div className="alert alert-warning" role="alert">
                    {t("purchases.purchaseUnitWarnining")}
                </div>
              <label htmlFor="purchaseUnit">{t("purchases.purchaseUnit")}</label>
              <div className="w-full">
              <Dropdown
                style={{width:"100%"}}
                options={purchaseUnitOptions}
                value={selectedPurchaseUnit}
                isSearchable={true}
                onChange={(purchaseUnit) =>
                    {
                        setSelectedPurchaseUnit(purchaseUnit.value)
                    }
                }
                isDisabled={![8,13].includes(user?.purchaseRole?.id)}
                placeholder={t("invoice.select")}
                name="purchaseUnit"
              />
              </div>


                <div className="d-flex justify-content-end mt-3">
                <div className="btn btn-primary mr-2" onClick={ async ()=>{
                    const sentForApprovalBody={
                        status: "DELIVERED",
                        workflowStatus: "Approval",
                        action: "submit for approval",
                    }
                    saveForApprovalPurchase(sentForApprovalBody)
                }}>
                     {actionLoading ? <Loader size="xs" /> :  t(["purchases.submitForApproval"])}
                </div>
                <div className="btn btn-secondary mr-2" onClick={ async ()=>{setOpenApprovePurchaseWorkflow(false)}}>
                     Close
                </div>
                </div>

            </Dialog>
        </>
    )

    const approveWorkflowOtherBody = (invoice) => {
        if (invoice?.internalStatus !== "Draft" || invoice.purchaseStatus!=="DELIVERED" ||  !invoice.id || ![8].includes(user?.purchaseRole?.id) || !invoice.extracted ) return <></>
        return <Checkbox
            onChange={
                (e) => {
                    setSelectedRows((state) => {
                        const _state = [...state]
                        const checkedValueIndex = _state.findIndex(sRow => sRow == invoice?.id)
                        if (checkedValueIndex >= 0) {
                            _state.splice(checkedValueIndex, 1)
                            return _state
                        }
                        _state.push(invoice.id)
                        return _state
                    })
                }
            }
            checked={!!selectedRows.find(sRow => sRow == invoice?.id)}

        />
    }

    const headerButtonsRight = (fullCompany?.workflow?.id==1) ? approvePurchasesWorkflow1:approvePurchaseWorkflowOther
    const approveMultiInovicesFilter =(fullCompany?.workflow?.id==1)? approveMultiInovicesFilterWorkflow1:approveMultiInovicesFilterWorkflowOther
    const approveMultiInovicesBody=(fullCompany?.workflow?.id==1)? approveWorkflow1Body:approveWorkflowOtherBody

    const typeOptions=[
        {
            key: "isOnlyFisc",
            count: statistics?.onlyFisc,
            label: t("parameters.onlyFiscalization"),
            value: "true",
        },
        {
            key: "isCustom",
            count: statistics?.importCustomNo,
            label: "Deklarate Doganore",
            value: "true",
        },
        {
            key: "isEinvoice",
            count: statistics?.einvoiceNo,
            label: "E-Invoice",
            value: "true",
        },
    ]

    const customDateRanges=
        [
            {
            label: t("common.thisYear"),
            value: 'thisYear',
            onDateChange: () => {
              let startDate = moment().startOf('Y')._d;
              let endDate = moment().endOf('day')._d;
              return [startDate, endDate];
            },
          },
            {
            label: t("common.lastYear"),
            value: 'lastYear',
            onDateChange: () => {
            let startDate = moment().subtract(1, "year").startOf('year').toDate();
            let endDate = moment().subtract(1, "year").endOf('year').toDate();
              return [startDate, endDate];
            },
          },
        ]


    return  (
        <>
            <div className="d-flex flex-wrap justify-content-between align-items-center my-1">
                <div className="left-header d-flex align-items-center">
                    <h3 className="mb-0 d-flex align-items-center">
                        {t("navbar.purchases")}{" "}
                    </h3>
                    <small className="ml-3">
                        <ExportCsvMultiple
                            selectedColumns={selectedColumns}
                            data={invoicesForCSV}
                            columns={columns}
                            onClick={() => getFilteredResults({ getInvoicesForCVS: true })}
                            excludedColumns={["actions", "print", "activities"]}
                        />
                    </small>
                    <AlertCertificate />
                    <AlertSubscription />
                </div>
                <CisMessage objectType={"GET_P_INVOICES_FROM_CIS"} />
                <div className="ml-md-auto mb-md-0">
                    <DateRange
                        fromDate={purchaseDates.fromDate}
                        toDate={purchaseDates.toDate}
                        customDateRanges={customDateRanges}
                        onChange={(dates) => {
                            let fromDate = dates[0];
                            let toDate = dates[1];
                            setFilters((filters) => ({
                                ...filters,
                                fromDate,
                                toDate,
                            }));
                            setPurchaseDates(state=>({...state,fromDate,toDate}))
                        }}
                    />
                </div>

                <div className="col-6 col-md-2 d-flex pr-0 align-items-center">
                    <label className="align-items-center mr-2 mb-0">BU</label>
                    <Select
                        styles={selectStyle}
                        className="w-100"
                        options={businessUnitCodes}
                        value={
                            persistedData?.businessUnitCode
                                ? businessUnitCodes.find(
                                    (businessUnit) =>
                                        businessUnit.value === businessUnitSelected
                                )
                                : { label: t("invoice.businessUnitCode"), value: "" }
                        }
                        isSearchable={true}
                        onChange={(bu) => {
                            handleParamsChange(null, "purchaseUnit", []);
                            setPersistedData({
                                ...persistedData,
                                businessUnitCode: bu.value,
                            });
                            let buReset = bu.value ? false : true // needed because setState is async
                            let purchaseUnits = bu.value ? tcrList(bu.value, false).map((tcr) => tcr.value) : []
                            getFilteredResults({
                                data: {
                                    ...filters,
                                    params: {
                                        ...filters.params,
                                        purchaseUnit: purchaseUnits,
                                    },
                                }, buReset
                            });
                        }}
                        name="purchaseUnit"
                    />
                </div>
            </div>
            <div className="row px-1">
                <ul className="col-12 col-sm-6 col-md-3 p-2 mb-1">
                    {filterStatisticCard(cisStatusOptions, { title: t("purchases.cisStatus") })}
                </ul>
                <ul className="col-12 col-sm-6 col-md-3 p-2 mb-1">
                    {filterStatisticCard(internalStatusOptions, {
                        title: t("purchases.inProcess"),
                    })}
                </ul>
                <ul className={"col-12 col-sm-5 col-md-2 p-2 mb-1"}>
                    {filterStatisticCard(typeOptions, {
                        title: t("invoice.type"),
                        cardClasses: "grid-2",
                        // isCount:false
                    })}
                </ul>
                <ul className={"col-12 col-sm-5 col-md-2 p-2 mb-1"}>
                    {filterStatisticCard(currencyOptions, {
                        cardClasses: "grid-2",
                        title: t("invoice.currency"),
                    })}
                </ul>
                {(<ul className="col-12 col-sm-6 col-md-2 p-2 mb-1">
                    {filterStatisticCard(otherOptions, {
                        cardClasses: "grid-2",
                        title: t("invoice.other"),
                        // type: "checkbox",
                    })}
                </ul>)}
            </div>
            <div className="card border-0 shadow">
                <div className="card-body p-0">
                    <PrimeTable
                        loading={loading}
                        showFilters={true}
                        reorderableColumns={true}
                        filters={[filters, setFilters]}
                        likeValues={false}
                        defaultFilters={defaultFilters}
                        tableRows={[tableRows, setTableRows]}
                        persistedRowPerPage={[persistedRowPerPage, setPersistedRowPerPage]}
                        value={invoices ?? []}
                        selectedColumns={[selectedColumns, setSelectedColumns]}
                        columns={columns}
                        dataKey="id"
                        onRemoteDataChange={(remoteData) => {
                            const updatedFilters = remoteData.filters ?? filters
                            const updatedTableData = remoteData.tableRows ?? tableRows
                            let { currentPage, rowsPerPage,sortCriteria } = updatedTableData
                            sortCriteria=sortCriteria?[sortCriteria]:[]
                            getFilteredResults({ data: updatedFilters, pageSize: rowsPerPage, page: currentPage,sortCriteria })
                        }}
                        expandedRows={expanded.expandedRows}
                        onRowCollapse={onRowCollapse}
                        onRowExpand={onRowExpand}
                        rowExpansionTemplate={rowExpansionTemplate}
                        headerButtonsRight={headerButtonsRight}
                        headerButtonsLeft={headerLeft}
                        columnDeps={[fullCompany, invoices?.length,loadingPdf]}//when should the colums should be rerendered,invoices length is needed because of thermal print
                        defaultSortCriteria={defaultSortCriteria}
                    >
                  {fullCompany?.groupAction==true &&     
                  <Column
                            filter={true}
                            showFilterMenu={false}
                            showClearButton={false}
                            filterElement={approveMultiInovicesFilter}
                            body={approveMultiInovicesBody}
                            headerStyle={{ width: '3rem' }} frozen />}
                        <Column
                            expander={() => true}
                            style={{ width: "0.5em" }}
                            header=""
                            field="expansion"
                            frozen={true}/>
                    </PrimeTable>
                </div>
            </div>
            {pdfEmbed}
        </>
    ) 
};

export default PurchasesNew;
