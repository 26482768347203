import React from "react";
import { useTranslation } from "react-i18next";
import { Form} from "react-bootstrap";
import Select from "react-select";
import PopOver from "../../global/PopOver";
import Swal from "sweetalert2";
import { useAppContext } from "../../../AppContext";
import countries from "../../../services/countries.json";
import profileIDS from "../../../services/profileIDS.json";
import invoiceTypes from "../../../services/InvoiceTypes.json";
import DatePicker from "react-datepicker";
import {
  faPlus,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import func from "../../../services/functions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { typeOfPaymentMeansCash, typeOfPaymentMeansNonCash } from "../WizardSteps/data";

const DemoInvoiceFiscalization = ({
  handleInvoiceTcrCodeChange,
  handleSelfInvoiceTypeChange,
  handleReverseChargeChange,
  invoiceState,
  setInvoiceState,
  disabled,
}) => {
  const { t } = useTranslation("translations");
  const { unitsOfMeasure } = useAppContext()
  const handlePaymentMeansChange = (paymentMean, index) => {
    const { paymentMeans, paymentMeansValue = "" } = paymentMean
    setInvoiceState((invoiceState) => {
      let newState = {
        ...invoiceState,
        paymentMeans
      }

      if (invoiceState?.paymentMethod) {
        const paymentMethod = invoiceState.paymentMethod.map((payment, i) => {
          if (index === i) {
            return {
              ...payment,
              paymentMeans,
              paymentMeansValue
            }
          }
          return payment
        })
        newState.paymentMethod = paymentMethod
      }
      return newState

    });
  }


  const {
    user: [user],
    selectedCompany: [selectedCompany],
    company: [fullCompany],
  } = useAppContext();

  const company = user?.companiesRights.find(
    (company) => company.id === parseInt(selectedCompany)
  );

  const profileIdOptions = profileIDS.map((profId) => ({
    ...profId,
    label: t([profId.label]),
  }));

  let unitsOfMeasureDefault =
    unitsOfMeasure?.find((unitsOfMeasure) => unitsOfMeasure?.code === "C62") ??
    null;

  const branch =
    company.branches.find((branch) => branch.default) || company.branches[0];

  const buyerForSelfInvoice = {
    tin: company.nuis,
    address: branch.sellerAddress,
    country:
      countries.find((country) => country.label === branch.sellerCountry)
        ?.value || "Albania",
    town: branch.sellerTown,
    name: company.name,
    buyerIDType: "NUIS",
  };

  //   only fiscalization function
  function changeOnlyFisc() {
    if (!invoiceState.einvoice) {
      setInvoiceState((invoiceState) => ({
        ...invoiceState,
        einvoice: true,
        parties: {
          ...invoiceState.parties,
          buyer: {
            ...invoiceState.parties.buyer,
            buyerIDType: "NUIS",
          },
        },
        paymentMethodType: "noncash",
        paymentMeans: "ZZZ",
      }));
    } else {
      Swal.fire({
        title: t(["notification.einvoice"]),
        icon: "warning",
        width: "800px",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "OK",
      }).then((result) => {
        if (result.value == true) {
          setInvoiceState((invoiceState) => ({
            ...invoiceState,
            einvoice: false,
          }));
        }
      });
    }
  }





  const handlePaymentMethodTypeChange = (newPaymentMethodType) => {

    let newPaymentMeans = "";
    let paymentMeansValue = ""

    if (newPaymentMethodType === "noncash") { newPaymentMeans = "ZZZ"; paymentMeansValue = "OTHER" }
    else {
      newPaymentMeans = "10"
      paymentMeansValue = "BANKNOTE"
    };

    setInvoiceState((invoiceState) => {
      const newState = {
        ...invoiceState,
        paymentMethodType: newPaymentMethodType,
        paymentMeans: newPaymentMeans,
      }
      // let paymentMeansValue = filteredOptions[0].paymentMeansValue

      if (invoiceState?.paymentMethod) {
        const paymentMethod = [
          {
            paymentMethodType: newPaymentMethodType,
            paymentMeans: newPaymentMeans,
            paymentAmount: "",
            company: selectedCompany,
            paymentMeansValue
          }
        ]
        newState.paymentMethod = paymentMethod
      }

      return newState

    });
  };




  const handleInvoiceChange = (value, key) => {
    const keys = key.split(".");

    switch (keys.length) {
      case 1:
        setInvoiceState((invoiceState) => ({
          ...invoiceState,
          [keys[0]]: value,
        }));
        break;
      case 2:
        setInvoiceState((invoiceState) => ({
          ...invoiceState,
          [keys[0]]: {
            ...invoiceState[keys[0]],
            [keys[1]]: value,
          },
        }));
        break;
      case 3:
        setInvoiceState((invoiceState) => ({
          ...invoiceState,
          [keys[0]]: {
            ...invoiceState[keys[0]],
            [keys[1]]: {
              ...invoiceState[keys[0]][keys[1]],
              [keys[2]]: value,
            },
          },
        }));
        break;
      default:
        return;
    }
  };

  const branchesOptions = company?.branches.map((branch) => {
    return {
      ...branch,
      label: branch.businUnitCode + " - " + branch.sellerAddress,
      value: branch.businUnitCode + " - " + branch.sellerAddress,
    };
  });

  /////  profile-id function
  const handleInvoiceBusinessUnitCodeChange = (businessUnitCode) => {
    const branch = branchesOptions?.find(
      (branch) => branch.businUnitCode == businessUnitCode
    );
    const tcrCode =
      branch.tcrTypes.filter((tcrType) => {
        return tcrType?.allowInvoice;
      })[0]?.tcrCode || "";

    const seller = branch
      ? {
        ...invoiceState.parties.seller,
        address: branch.sellerAddress,
        country:
          countries.find((country) => country.label === branch.sellerCountry)
            ?.value || "Albania",
        town: branch.sellerTown,
        name: company.name,
      }
      : invoiceState.parties.seller;

    setInvoiceState((invoiceState) => ({
      ...invoiceState,
      parties: { ...invoiceState.parties, seller },
      businessUnitCode,
      tcrCode,
    }));
  };
  


  const tcrList = React.useMemo(() => {
    let filteredBranch = company.branches.find(
      (branch) => branch.businUnitCode == invoiceState.businessUnitCode
    );
    let tcrTypes =
      filteredBranch?.tcrTypes.filter((tcrType) => tcrType?.allowInvoice) ?? [];
    let tcrList =
      tcrTypes.map((tcr) => {
        let sellerName = filteredBranch.sellerName;
        return {
          label: tcr.tcrCode + "-" + sellerName,
          value: tcr.tcrCode,
        };
      }) ?? [];

    return tcrList.length > 0
      ? [{ label: "Select", value: "" }].concat(tcrList)
      : tcrList;
  }, [selectedCompany, invoiceState?.businessUnitCode]);

  const setBadDebt = (badDebtInv) => {
    if (badDebtInv) {
      setInvoiceState(invoiceState => ({
        ...invoiceState,
        badDebtInv: true,
        correctiveInv: false,
      }))
    }
    else {
      setInvoiceState(invoiceState => ({
        ...invoiceState,
        badDebtInv: false,
        correctiveInv: true,
      }))
    }
  }


  const handleDeletePayment = (index) => {
    setInvoiceState((invoiceState) => {
      const paymentMethod = invoiceState.paymentMethod.filter(
        (_, i) => i !== index
      );
      return {
        ...invoiceState,
        paymentMethod,
      };
    });
  };

  const handleInvoiceTypeChange = (type) => {
    let newInvoiceState;
    switch (parseInt(type)) {
      case 389:
        newInvoiceState = {
          type,
          selfInvoice: true,
          einvoice: false,
          badDebtInv: false,
          correctiveInv: false,
          correctiveInvType: "",
          parties: {
            buyer: buyerForSelfInvoice,
          },
        };
        break;
      case 384:
        newInvoiceState = {
          type,
          selfInvoice: false,
          correctiveInv: true,
          correctiveInvType: "CORRECTIVE",
        };
        break;

      default:
        newInvoiceState = {
          type,
          selfInvoice: false,
          einvoice: true,
          badDebtInv: false,
          correctiveInv: false,
        };
    }

    if (
      type != 389 &&
      invoiceState.parties.buyer.tin === invoiceState.parties.seller.tin
    ) {
      newInvoiceState.parties = {
        buyer: {
          tin: "",
          name: "",
          address: "",
          country: "",
          town: "",
          buyerIDType: "NUIS",
        },
      };
    }

    setInvoiceState((invoiceState) => ({
      ...invoiceState,
      ...newInvoiceState,
      parties: {
        ...invoiceState.parties,
        ...newInvoiceState?.parties,
      },

    }));
  };


  const handleAddPayment = (filteredOptions) => {
    if (filteredOptions.length == 0) return
    setInvoiceState((invoiceState) => {
      const { paymentMethodType, paymentMeans } = invoiceState
      let newPaymentMeans = filteredOptions[0].paymentMeans
      let paymentMeansValue = filteredOptions[0].paymentMeansValue
      const paymentMethod = invoiceState?.paymentMethod ? [...invoiceState?.paymentMethod] : []

      const newPaymentMethod = {
        paymentMethodType,
        paymentMeans: newPaymentMeans,
        paymentAmount: "",
        company: selectedCompany,
        paymentMeansValue
      }

      if (invoiceState?.paymentMethod?.length == 0) {
        const oldPaymentMethod = {
          paymentMethodType,
          paymentMeans,
          paymentAmount: "",
          company: selectedCompany,
          paymentMeansValue
        }
        paymentMethod.push(oldPaymentMethod)
      }

      paymentMethod.push(newPaymentMethod)

      return {
        ...invoiceState,
        paymentMethod
      }

    });
  }

  const invoiceTypeOptions = invoiceTypes
    .filter((invoice) => invoice.disabled == false)
    .map((type) => {
      return {
        ...type,
        label: t([type.label]),
      };
    });

  const handleIicRefChange = (value) => {
    setInvoiceState((invoiceState) => ({
      ...invoiceState,
      iic_ref: value,
      refInvIIC: value,
    }));
  };

  const handleMeansValueChange = (value, index) => {
    setInvoiceState((invoiceState) => {
      const paymentMethod = invoiceState.paymentMethod.map((payment, i) => {
        if (index === i) {
          return {
            ...payment,
            paymentAmount: value,
          }
        }
        return payment
      })
      return {
        ...invoiceState,
        paymentMethod: paymentMethod
      }

    });
  }

  const typeOfSelfInvoiceOptions = [
    { label: "AGREEMENT", value: "1" },
    { label: "DOMESTIC", value: "2" },
    { label: "ABROAD", value: "3" },
    { label: "SELF", value: "4" },
    { label: "OTHER", value: "5" },
  ];

  const correctiveInvTypes = [
    { value: "CORRECTIVE", label: "Fature Korrigjuese" },
    { value: "DEBIT", label: "Shenim Debiti" },
    { value: "CREDIT", label: "Shenim Krediti" },
  ];

  const paymentMethodTypes = [
    { label: "Cash", value: "cash" },
    { label: "Non Cash", value: "noncash" },
  ];


  const customStyles = {
    option: (styles, state) => ({
      ...styles,
      color: state.isSelected ? "#000" : styles.color,
      backgroundColor: state.isSelected ? "#0075670d" : styles.color,
      borderBottom: "0.3px solid rgba(40, 167, 69, 0.25)",
      "&:hover": {
        color: "#000000",
        backgroundColor: "#0075670d",
      },
    }),
    control: (styles, state) => ({
      ...styles,
      boxShadow: state.isFocused ? "0 0 0 0.16rem rgba(68, 68, 68, 0.135)" : 0,
      borderWidth: state.isFocused ? "1px" : "1px",
      borderColor: state.isFocused ? "#28f0d8" : "#CED4DA",
      minHeight: "14px",
      maxHeight: "34px",
      fontSize: "0.8rem",
      marginBottom: "0.1rem",
      "&:hover": {
        borderColor: state.isFocused ? "#28f0d8" : "#CED4DA",
      },
    }),
  };

  let check =
    invoiceState?.badDebtInv ||
    invoiceState?.sumInv ||
    invoiceState?.correctiveInv;


  const buildPaymentMethod = () => {
    if (invoiceState.paymentMethod?.length > 0) {
      return invoiceState.paymentMethod
    } else {
      const { paymentMethodType, paymentMeans } = invoiceState
      const paymentOptions = paymentMethodType == "cash" ? typeOfPaymentMeansCash(t) : typeOfPaymentMeansNonCash(t)
      const currentPaymentOption = paymentOptions.find(opt => opt.paymentMeans === paymentMeans)

      const content = {
        paymentMethodType,
        paymentMeans,
        paymentAmount: "",
        company: selectedCompany,
        paymentMeansValue: currentPaymentOption?.paymentMeansValue
      }
      return [content]
    }
  }

  const paymentMethod = buildPaymentMethod();

  const handleCorrectionOfSelfInvoice = (value) =>{
        setInvoiceState((state)=>{
          return {...state,correctionOfSelfInvoice:value, parties:{...state.parties,buyer:value?buyerForSelfInvoice:{...state.parties.buyer}}}
        })
      }

  return (
    <div>
      <div className="d-flex justify-content-between header border-bottom">
        <div>
          <Form.Label className="fw-bold ml-3">{t("invoice.type")}</Form.Label>
        </div>
      </div>
      <div className="row mx-1 mx-md-auto">
        <div className="col-12 col-md-6 iic-ref">
          <label className="field-label">
            {" "}
            {t("invoice.profileId")}
            <PopOver title="Profile ID" />
          </label>
          <Select
            styles={customStyles}
            options={profileIdOptions}
            isSearchable={true}
            onChange={(profileId) => {
              setInvoiceState((invoiceState) => ({
                ...invoiceState,
                profileId: profileId.value,
                type: profileId.types.includes(invoiceState.type)
                  ? invoiceState.type
                  : "",
              }));
            }}
            value={profileIdOptions.find(
              (profileId) => profileId.value === invoiceState.profileId
            )}
            name="profileId"
            isDisabled={disabled}
          />

          <label className="field-label mt-1">
            {" "}
            {t("home.branches")}
            <PopOver title={t("hints.branches")} />
          </label>

          <Select
            styles={customStyles}
            options={branchesOptions}
            isSearchable={true}
            onChange={(branch) =>
              handleInvoiceBusinessUnitCodeChange(branch.businUnitCode)
            }
            value={branchesOptions?.find(
              (branch) => branch.businUnitCode === invoiceState.businessUnitCode
            )}
            name="tcrCode"
            isDisabled={disabled}
          />

          <label className="field-label mt-1">
            {t("home.tcr")}
            <PopOver title={t("hints.tcr")} />
          </label>
          {
            <Select
              styles={customStyles}
              options={tcrList}
              isSearchable={true}
              onChange={(tcr) => handleInvoiceTcrCodeChange(tcr.value)}
              value={
                tcrList?.find((tcr) => tcr.value === invoiceState.tcrCode) || ""
              }
              name="tcrCode"
              isDisabled={disabled}
            />
          }
           {check &&
              <>
                <label className="form-label mt-2">
                {t("purchases.iicRef")} <span className="text-danger">*</span>
                  <PopOver title={t("invoice.IICInvoiceCorreced")} />
                </label>
                <input
                  name="iicRef"
                  id="iicRef"
                  type="text"
                  className="form-control form-control-sm "
                  disabled={disabled}
                  value={invoiceState.iic_ref}
                  onChange={e => handleIicRefChange(e.target.value)}
                />

                {invoiceState?.correctiveInv &&
                  <div className="custom-control custom-checkbox  mt-2">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="allowSameNipt"
                      name="allowSameNipt"
                      checked={invoiceState.correctionOfSelfInvoice}
                      onChange={e => handleCorrectionOfSelfInvoice(e.target.checked)}
                      disabled={invoiceState.type == 389 || disabled}
                    />
                    <label htmlFor="allowSameNipt" className="custom-control-label">
                     { t("invoice.correctSelfInv")}
                      <PopOver title={t("invoice.checkForSameNipt")} />
                    </label>
                  </div>}

                <label className="form-label mt-2">
               {t("invoice.refIssueDate")} <span className="text-danger">*</span>
                  <PopOver title={t("invoice.DateIssueCorreced")} />
                </label>
                <DatePicker
                  selected={invoiceState.refInvIssueDateTime}
                  disabled={disabled}
                  dateFormat="dd/MM/yyyy"
                  name="refInvIssueDateTime"
                  id="refInvIssueDateTime"
                  autoComplete="off"
                  showDisabledMonthNavigation
                  onChange={date => handleInvoiceChange(date, 'refInvIssueDateTime')}
                  customInput={<input className="form-control" />}
                />
              </>}

              {invoiceState?.type == 384 &&
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="badDebtInv"
                    name="badDebtInv"
                    checked={invoiceState.badDebtInv}
                    onChange={e => setBadDebt(e.target.checked)}
                    disabled={disabled}
                  />
                  <label htmlFor="badDebtInv" className="custom-control-label mt-2">
                    Fature Borxhi Keq
                    <PopOver title="Zgjidhni kte opsion ne qofte se fatura eshte borxh i keq" />
                  </label>
                </div>}

        </div>

        <div className="col-12 col-md-6">

          <label className="field-label">
            {t("home.invoiceType")}
            <PopOver title={t("hints.invoiceType")} />
          </label>

          <Select
            styles={customStyles}
            options={invoiceTypeOptions.filter((invoiceType) =>
              profileIdOptions
                .find((profileId) => profileId.value === invoiceState.profileId)
                ?.types.includes(invoiceType.value)
            )}
            value={
              invoiceTypeOptions.find(
                (invoiceType) => invoiceType.value === invoiceState.type
              ) || ''
            }
            isSearchable={true}
            onChange={(invoiceType) => {
              handleInvoiceTypeChange(invoiceType.value);
            }}
            isOptionDisabled={(option) => option.disabled}
            isDisabled={disabled}
            name="type"

          />

          {(invoiceState.type == 389 || invoiceState.correctionOfSelfInvoice) && (
            <div>
              <label className="field-label mt-1">
                {t("home.typeOfSelfInvoice")}{" "}
                <span className="text-danger">*</span>
                <PopOver title={t("hints.selfInvoice")} />
              </label>
              <Select
                styles={customStyles}
                options={typeOfSelfInvoiceOptions}
                id="typeOfSelfInvoice"
                name="typeOfSelfInvoice"
                value={typeOfSelfInvoiceOptions.find(
                  (typeOfSelfInvoiceOptions) =>
                    typeOfSelfInvoiceOptions.value ===
                    invoiceState.typeOfSelfInvoice
                )}
                isDisabled={disabled}
                onChange={(e) => {
                  handleSelfInvoiceTypeChange("typeOfSelfInvoice", e.value);
                }}
              />

              <div
                className="custom-control custom-checkbox"
                style={{ zIndex: 0, marginTop: "0.5rem" }}
              >
                <input
                  checked={invoiceState.reverseCharge}
                  type="checkbox"
                  className="custom-control-input mt-1"
                  id="isReverseCharge"
                  name="isReverseCharge"
                  disabled={disabled}
                  //  value="FiscInvoice"
                  //defaultChecked={customExtension ? true : false}
                  onChange={handleReverseChargeChange}
                />
                <label
                  style={{ paddingTop: "3px", paddingLeft: "2px" }}
                  className="custom-control-label"
                  htmlFor="isReverseCharge"
                >
                  Is Reverse Charge
                </label>
              </div>
            </div>
          )}

          {invoiceState.type == 384 && (
            <>
              <label className="field-label mt-1">
                Corrective Type
                <PopOver title="Corrective Type" />
              </label>
              <Select
                styles={customStyles}
                options={correctiveInvTypes}
                value={correctiveInvTypes.find(
                  (correctiveInvType) =>
                    correctiveInvType.value === invoiceState.correctiveInvType
                )}
                isSearchable={true}
                onChange={(correctiveInvType) => {
                  setInvoiceState((invoiceState) => ({
                    ...invoiceState,
                    correctiveInvType: correctiveInvType.value,
                  }));
                }}
                isDisabled={disabled}
                name="correctiveInvType"
              />
            </>
          )}
       

          <label className="field-label mt-1">
            {t("home.paymentMethodType")}{" "}
            <PopOver title={t("hints.paymentMethod")} />
          </label>
          <Select
            styles={customStyles}
            options={paymentMethodTypes.filter((methodType) => {
              if (invoiceState.einvoice) return methodType === "noncash";
              else return methodType;
            })}
            value={paymentMethodTypes.find((type) => {
              if (invoiceState.einvoice) {
                return type.value === "noncash";
              } else return type.value === invoiceState.paymentMethodType;
            })}
            isSearchable={true}
            onChange={(paymentMethodType) => {
              handlePaymentMethodTypeChange(paymentMethodType.value);
            }}
            //isDisabled={invoiceState.einvoice}
            name="paymentMethodType"
            isDisabled={disabled}
          />

          {paymentMethod.map((payment, index) => {
            const { paymentMethodType, paymentMeans, paymentMeansValue, paymentAmount } = payment
            const options = paymentMethodType == "noncash" ? typeOfPaymentMeansNonCash(t) : typeOfPaymentMeansCash(t)
            const filteredOptions = options.filter(opt => (payment.paymentMeans == opt.paymentMeans && payment.paymentMeansValue == opt.paymentMeansValue) || !paymentMethod.find(pm => pm.paymentMeans === opt.paymentMeans && pm.paymentMeansValue === opt.paymentMeansValue))
            const firstElement = index == 0
            return (
              <div className="col-12 p-0">
                <div className="d-flex justify-content-between align-items-center">
                  <label className="form-label mt-2">
                    {t('home.paymentMeans')}
                  </label>
                  {firstElement && !disabled && <div >
                    <button className="btn btn-outline-primary btn-xs"  style={{ minWidth: "55px" }} onClick={() => {
                      const currentFilteredOptions = options.filter(opt => !paymentMethod.find(pm => pm.paymentMeans === opt.paymentMeans && pm.paymentMeansValue === opt.paymentMeansValue))
                      handleAddPayment(currentFilteredOptions)
                    }}>
                       {t('item.add')} {"  "}
                      <FontAwesomeIcon icon={faPlus} />
                    </button>
                  </div>}
                </div>
                <div>
                  <div style={{ flex: 1 }}>
                    <Select styles={customStyles}
                      options={filteredOptions.map(opt => ({ ...opt, value: opt?.paymentMeans + opt?.paymentMeansValue }))}
                      value={filteredOptions.find(opt => {
                        return opt.paymentMeans === paymentMeans && paymentMeansValue === opt.paymentMeansValue
                      })}
                      getOptionValue={(opt) => opt.paymentMeans + opt?.paymentMeansValue}
                      onChange={(paymentMean) => {
                        handlePaymentMeansChange(paymentMean, index)
                      }}
                      isDisabled={disabled} />
                  </div>
                  {paymentMethod.length > 1 && <div className="d-flex" style={{ flex: 1 }}>
                    <div className="w-full">

                      <input
                        placeholder="E.g 100"
                        type={disabled ? "text" : "number"}
                        step="0.01"
                        min="0.01"
                        className="form-control form-control-sm text-right"
                        disabled={disabled}
                        value={
                          disabled ? func.formattedPrice(paymentAmount, 10)
                            : paymentAmount
                        }
                        onChange={e => handleMeansValueChange(e.target.value, index)}
                      />
                    </div>
                    {!disabled && <div>
                      <button className="btn btn-outline-danger btn-xs" onClick={() => handleDeletePayment(index)}><FontAwesomeIcon icon={faTrash} /></button>
                    </div>}
                  </div>}
                </div>


              </div>
            )
          })}


        </div>
      </div>
    </div>
  );
};

export default DemoInvoiceFiscalization;
