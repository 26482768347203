import React, { useState } from 'react'
import PrimeTable from '../PrimeTable/PrimeTable';
import mainAxios from '../../services/mainAxios';
import { useAppContext } from '../../AppContext';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faPlus } from '@fortawesome/free-solid-svg-icons';
import CellInput from '../global/CellInput';
import { Dialog } from 'primereact/dialog';
import EditAddSupplies from './EditAddSupplies';
import moment from 'moment';
import { getTcrList } from '../../services/data';


const MySuppliers = () => {
    const {
        user: [user],
        selectedCompany: [selectedCompany],
    } = useAppContext();

    const company = user.companiesRights.find(
        (company) => company.id === parseInt(selectedCompany)
    );
    const defaultFilters = {}

    const [filters, setFilters] = useState({
        params:{}
      });

    const [clients, setClients] = useState([]);
    const [open, setOpen] = useState({id:null,open:false});

    const defaultSortCriteria={
        field: "recordDate",
        direction: "DESC"
      } 

    const [tableRows, setTableRows] = useState({
        totalRows: 0,
        rowsPerPage: 10,
        currentPage: 1,
        sortCriteria:defaultSortCriteria

      });

    const { t } = useTranslation("translations");

    const tinTypes = [
        { label: "NUIS", value: "NUIS" },
        { label: "ID", value: "ID" },
        { label: "PASS", value: "PASS" },
        { label: "VAT", value: "VAT" },
        { label: "TAX", value: "TAX" },
        { label: "SOC", value: "SOC" },
    ];
    

    React.useEffect(() => {
        getFilteredResults();
      },[]);

    const getFilteredResults = async ({
        updatedFilters,
        rowsPerPage = 10,
        currentPage = 1,
        sortCriteria = [ defaultSortCriteria ]
      }={}) => {
       return await mainAxios
            .post("/apiEndpoint/search", {
                object: "GetConnections",
                value: null,
                params: updatedFilters?.params,
                isSupplier: true,
                company: company.id,
                pagination:{
                    pageSize: rowsPerPage,
                    pageNumber: currentPage,
                  },
                  sortCriteria
            })
            .then((res) => {
                if (res.data.status === true) {
                    setTableRows((tableRows) => ({
                        ...tableRows,
                        totalRows: res?.data?.totalSize,
                      }));
                    setClients(res.data.content);

                }
            });
    };

    const tcrList = React.useMemo(() => {
        return getTcrList(company)
    }, [selectedCompany]);


    const columns = [
        {
            header: t(["home.actions"]),
            style: { minWidth: "130px", maxWidth: "130px" },
            body: (client) => (
                <>
                    <div
                        className="text-primary"
                        style={{ cursor: "pointer" }}
                        onClick={() => { setOpen({id:client.id,open:true}) }}
                    >
                        <FontAwesomeIcon icon={faEdit} className="mr-1" />
                        {t("company.edit")}
                    </div>
                </>
            ),
        },
        {
            header: `${t("item.name")}`,
            field: "name",
            filter: true,
            style: { minWidth: "180px", maxWidth: "180px" },
            sortable: true,
        },
        {
            header: `${t("table.recordDate")}`,
            field: "recordDate",
            body: supplier => supplier.recordDate ? moment(supplier.recordDate).format("DD/MM/YYYY") : "",
            filter: true,
            filterType: "DATE",
            filterConfig:{
                field:"invoiceDate"
            },
            style: { minWidth: "180px", maxWidth: "180px" },
            sortable: true,
        },
        {
            header: `${t("table.recordUser")}`,
            field: "recordUser",
            filter: true,
            style: { minWidth: "150px", maxWidth: "150px" },
            sortable: true,
        },
        {
            header: `Id Value`,
            field: "idValue",
            filter: true,
            style: { minWidth: "180px", maxWidth: "180px" },
            sortable: true,
        },
        {
            header: `${t("invoice.idType")}`,
            field: "idType",
            filter: true,
            filterType: "DROPDOWN",
            filterConfig: {
                options: tinTypes,
                optionValue: "value",
                optionLabel: "label",
            },
            style: { minWidth: "180px", maxWidth: "180px" },
            sortable: true,
        },
        {
            header: `${t("purchases.purchaseUnit")}`,
            field: "purchaseUnit",
            filter: true,
            filterType: "DROPDOWN",
            filterConfig: {
                options: tcrList,
                optionValue: "value",
                optionLabel: "label",
            },
            style: { minWidth: "180px", maxWidth: "180px" },
            sortable: true,
        },
        {
            header: `${t("customers.address")}`,
            field: "address",
            filter: true,
            sortable: true,
            body: (queue) => <CellInput width={180} value={queue.address ?? ""} onFocus={(event) => event.target.select()} />
        },
        {
            header: `${t("customers.town")}`,
            width: "125px",
            field: "town",
            style: { minWidth: "150px", maxWidth: "150px" },
            filter: true,
            sortable: true,
        },
        {
            header: `${t("customers.country")}`,
            width: "80px",
            style: { minWidth: "150px", maxWidth: "150px" },
            field: "country",
            filter: true,
            sortable: true,
        },
        {
            header: `${t("invoice.nickName")}`,
            field: "nickname",
            style: { minWidth: "150px", maxWidth: "150px" },
            filter: true,
            sortable: true,
        },
        {
            header: `${t("users.phone")}`,
            field: "phoneNumber",
            filter: true,
            style: { minWidth: "150px", maxWidth: "150px" },
            sortable: true,
        },
        {
            header: `${t("email.email")}`,
            field: "email",
            filter: true,
            style: { minWidth: "150px", maxWidth: "150px" },
            sortable: true,
        },
        {
            header: `Vendor Code`,
            field: "vendorCode",
            style: { minWidth: "150px", maxWidth: "150px" },
            filter: true,
            sortable: true,
        },

    ];
    
    const clearSupplier = () => {
        setOpen({id:null,open:false})
    }
    

    return (
        <div>
            <Dialog
                header={`${open.id ? t("mySuppliers.editSupplier") : t("mySuppliers.addSupplier")}`}
                visible={open.open}
                onHide={() => clearSupplier()}
                style={{ width: "750px" }}
                breakpoints={{ "960px": "75vw", "641px": "100vw" }}
            >
                {open?.open &&<EditAddSupplies
                    onDataSuccess={()=>{
                        setOpen({id:null,open:false});
                        getFilteredResults({updatedFilters:filters, rowsPerPage:tableRows.rowsPerPage, currentPage:tableRows.currentPage})
                    }}
                    clearSupplier={clearSupplier}
                    tcrList={tcrList}
                    id={open?.id}
                />}
            </Dialog>

            <div className="d-flex justify-content-between align-items-center my-2">
                <h3 className="d-flex align-items-center p-0 m-0">
                    {t("navbar.mySuppliers")}

                </h3>
                <div className="page-actions">
                    <button
                        // disabled={editClients[0]?.newSupplier}
                        className="btn btn-sm btn-primary"
                        onClick={() => setOpen({id:null,open:true})}
                    >
                        <FontAwesomeIcon icon={faPlus} className="mr-2" />
                        {t("mySuppliers.addSupplier")}
                    </button>
                </div>
            </div>
            <div className="card border-0 shadow">
                <div className="card-body">
                    <PrimeTable
                        value={clients}
                        columns={columns}
                        defaultFilters={defaultFilters}
                        filters={[filters,setFilters]}
                        onRemoteDataChange={(remoteData) => {
                            const updatedFilters = remoteData.filters ?? filters
                            const updatedTableData = remoteData.tableRows ?? tableRows
                            let { currentPage, rowsPerPage,sortCriteria } = updatedTableData
                            sortCriteria = sortCriteria ? [sortCriteria] : null
                            getFilteredResults({updatedFilters, rowsPerPage, currentPage,sortCriteria})
                        }}
                        responsiveLayout="scroll"
                        tableRows={[tableRows, setTableRows]}
                        defaultSortCriteria={defaultSortCriteria}
                        
                    />
                </div>
            </div>

        </div>
    )
}

export default MySuppliers