import { faCheck, faFileInvoice, faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react'
// import selectStyle from '../../design/selectStyle';
import Select from "react-select";
import countries from '../../services/countries.json'
import Loader from '../Layout/Loader';
import { toast } from 'react-toastify';
import { useAppContext } from '../../AppContext';
import mainAxios from '../../services/mainAxios';
import { useTranslation } from 'react-i18next';
import { getTcrList, tinTypes } from '../../services/data';
import { sellerPartyToConnection } from '../../services/functions';

const EditAddSupplies = ({ onDataSuccess,cancelSupplier,errors:errorsAbove,invoiceState,setInvoiceState,id,isNew,...props  }) => {
  const [loading, setLoading] = useState(false)
  const [validationErrors, setValidationErrors] = useState({});
  const [pageLoading,setPageLoading]=useState(true)
  console.log({validationErrors});
  const fromInvoice = !!invoiceState

  const supplierDefault = {
    name: '',
    idValue: "",
    nickname: '',
    address: '',
    phoneNumber: '',
    email: '',
    vendorCode: '',
    town: '',
    purchaseUnit: '',
    country2a: '',
    country3a: '',
    country: '',
    idType: "NUIS",
  }
  const [supplier, setSupplier] = useState(fromInvoice&& !isNew ?{ ...sellerPartyToConnection({party:invoiceState?.parties?.seller,supplierDefault})} : {...supplierDefault})

  const {
    user: [user],
    selectedCompany: [selectedCompany],
  } = useAppContext();


  const company = user?.companiesRights?.find(
    (company) => company.id === parseInt(selectedCompany)
);

  const connectionId = id ?id?.toString(): props?.match?.params?.id;

  useEffect(() => {
    if(!connectionId) {
      setPageLoading(false)
      return
    }
    mainAxios
      .post("apiEndpoint/search", {
        object: "GetConnections",
        params: {
          id: connectionId,
        },
      })
      .then((res) => {
        setPageLoading(false)
        if (res.data.status) {
          if(fromInvoice){
            setSupplier({ ...(res?.data?.content?.[0] ?? {}),...sellerPartyToConnection({party:invoiceState?.parties?.seller})})
          }else{
            setSupplier(res?.data?.content?.[0])
          }
        }
      })
  }, [connectionId]);

  const tcrList = React.useMemo(() => {
      return getTcrList(company)
  }, [selectedCompany]);

  const selectStyle = {
    option: (styles, state) => ({
      ...styles,
      color: state.isSelected ? "#000" : styles.color,
      backgroundColor: state.isSelected ? "#0075670d" : styles.color,
      borderBottom: "0.3px solid rgba(40, 167, 69, 0.25)",
      "&:hover": {
        color: "#000000",
        backgroundColor: "#0075670d",
      }
    }),
    control: (styles, state) => ({
      ...styles,
      boxShadow: state.isFocused ? "0 0 0 0.1rem rgba(68, 68, 68, 0.135)" : 0,
      borderWidth: state.isFocused ? "2px" : "1px",
      borderColor: state.isFocused ? "#28f0d8" : "#CED4DA",
      border: validationErrors.idType && validationErrors.idValue ? '1px solid red' : '',
      zIndex: state.isFocused ? 2 : 1,
      "&:hover": {
        borderColor: state.isFocused ? "#28f0d8" : "#CED4DA",
      }
    })
  };
console.log({supplier})
  const handleChange = (e) => {
    const { name, value } = e.target;
    setSupplier((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const { t } = useTranslation("translations");
  const [sellerInputClass, setSellerInputClass] = useState("");
  const [inputClass, setInputClass] = React.useState("");

  const handleClientChange = (key, value, length, searchInput = false) => {
    value = length !== undefined ? value.slice(0, length) : value;
    if (searchInput && inputClass.length > 0) setInputClass("");
    

    setSupplier((prevData) => {
      const updatedData = { ...prevData, [key]: value };

      let selectedCountry;
      if (key === "country") {
        selectedCountry = countries.find((country) => country.label === value);
      } else if (key === "country2a") {
        selectedCountry = countries.find((country) => country.shorterCode === value);
      } else if (key === "country3a") {
        selectedCountry = countries.find((country) => country.shortCode === value);
      }

      if (selectedCountry) {
        updatedData.country = selectedCountry.label;
        updatedData.country2a = selectedCountry.shorterCode;
        updatedData.country3a = selectedCountry.shortCode;
      }
  
      return updatedData;
    });
  };


  const handleSellerTaxPayerSearch = () => {
    let buyerNipt = supplier.idValue.split(" ").join("");
    if (
      buyerNipt &&
      !new RegExp("[a-zA-Z]{1}[0-9]{8}[a-zA-Z]{1}").test(buyerNipt)
    ) {
      toast.error(t(["toast.incorrectNipt"]), { containerId: "all_toast" });
      setSellerInputClass("error");
      return;
    }

    setSellerInputClass("loading");
    mainAxios
      .post("apiEndpoint/search", {
        object: "GetTaxpayersRequest",
        value: buyerNipt,
        nuis: user.companiesRights.find(
          (company) => company.id === parseInt(selectedCompany)
        )?.nuis,
      })
      .then((res) => {
        const content = res.data.content;
        if (res?.data?.status === true) {
          if (content.length === 0) {
            toast.warning(t(["toast.noTaxPayer"]), {
              containerId: "all_toast",
            });
            setSellerInputClass("error");
          } else {
            const clientData = content[0];

            setSupplier((prevData) => ({
              ...prevData,
              idValue: clientData?.tin ?? (supplier?.idValue),
              name: clientData.name,
              address: clientData.address,
              country: clientData.country,
            }));

            setSellerInputClass("success");
          }
        } else {
          setSellerInputClass("error");
        }
      })
      .catch((e) => {
        setSellerInputClass("error");
      });
  };

  const validate = () => {
    const errors = {};

    if (!supplier.name) errors.name = true;
    if (!supplier.town) errors.town = true;
    if (!supplier.country) errors.country = true;
    if (!supplier.idValue) errors.idValue = true;
    if (!supplier.address) errors.address = true;

    setValidationErrors(errors);
  
    return Object.keys(errors).length === 0;
  };

  const handleSaveChanges = ({saveInInvocieOnly=false}={}) => {
    if (!validate()) {
      toast.error("Please fix validation errors before saving.");
      return;
    }
    const nuis = user.companiesRights.find(
      (company) => company.id === parseInt(selectedCompany)
    )?.nuis;

    if (!nuis) {
      toast.error("Nuis not found.");
      return;
    }

    const isUpdating = supplier.id !== undefined;
    if(saveInInvocieOnly){
      onDataSuccess(supplier)
      return 
    }
    setLoading(true)

    mainAxios
      .post(`/apiEndpoint/saveOrUpdate`, {
        object: "Connections",
        nuis: nuis,
        username: user.username,
        content: {
          ...supplier,
          company: Number(selectedCompany),
          supplier: true,
        },
      })
      .then((response) => {
        setLoading(false)
        if (response.data.status) {
          if (isUpdating) {
            toast.success(t(["mySuppliers.updateSupplier"]), {
              containerId: "all_toast",
            });
          } else {
            toast.success(t(["mySuppliers.newSupplier"]), {
              containerId: "all_toast",
            });
          }
          onDataSuccess(response.data.content[0])
        } else {
          toast.error(response.data.message || "Failed to save supplier. Please try again.");
        }
      })
  };
  if(pageLoading) return <Loader/>


  const content = (
    <div className="container">
      <div className="row">
        {/* ID Type and ID Value in one row */}
        <div className="form-group col-md-6">
          <label htmlFor="idType">{t('invoice.idType')}</label>
          <span className="text-danger"> *</span>
          <Select
            menuPlacement="auto"
            styles={selectStyle}
            options={tinTypes}
            value={
              tinTypes?.find((idType) => idType?.label === supplier?.idType) || null
            }
            onChange={(idType) =>
              handleClientChange("idType", idType?.label)
            }
            placeholder={t("invoice.select")}
            name="idType"
          />
          {validationErrors.idType == 'ID' && <span className='text-danger'>{t('validateSuppliers.idType')}</span>}
        </div>

        {/* Conditional Field for NUIS in the same row */}
        <div className="form-group col-md-6">
          {supplier.idType === "NUIS" ? (
            <>
              <label className="form-label">
                (NIPT)
                {/* <span className="text-danger"> *</span> */}
              </label>
              <div className="input-group position-relative">
                <input
                  name="idValue"
                  type="text"
                  className={`form-control required ${sellerInputClass === "error"
                      ? "border-danger"
                      : sellerInputClass === "success"
                        ? "border-success"
                        : ""
                    }`}
                  value={supplier.idValue}
                  onChange={(e) =>
                    handleClientChange("idValue", e.target.value, 100, true)
                  }
                />
                <div className="input-group-append">
                  <span
                    className="input-group-text cursor-pointer"
                    onClick={handleSellerTaxPayerSearch}
                  >
                    <FontAwesomeIcon icon={faSearch} />
                  </span>
                </div>
                {sellerInputClass === "loading" && (
                  <div className="input-icon input-loader">
                    <Loader size="xs" />
                  </div>
                )}
                {sellerInputClass === "error" && (
                  <div className="input-icon text-danger">
                    <FontAwesomeIcon icon={faTimes} />
                  </div>
                )}
                {sellerInputClass === "success" && (
                  <div className="input-icon text-success">
                    <FontAwesomeIcon icon={faCheck} />
                  </div>
                )}
              </div>
            </>
          ) : (
            <>
              <label htmlFor="idValue">{supplier.idType ?? "Nipt"}</label>
              {supplier.idType == 'ID' && <span className="text-danger"> *</span>}
              <input
                type="text"
                id="idValue"
                name="idValue"
                className={`form-control ${supplier.idType === "ID" && validationErrors.idValue ? "is-invalid" : ""}`}
                value={supplier.idValue}
                onChange={handleChange}
              />
            </>
          )}
          {supplier.idType === "ID" && validationErrors.idValue && <span className='text-danger'>{t('validateSuppliers.idValue')}</span>}

        </div>

        {/* Name */}
        <div className="form-group col-md-6">
          <label htmlFor="name">{t("invoice.name")}</label>
          <span className="text-danger"> *</span>
          <input
            type="text"
            id="name"
            name="name"
            className={`form-control ${validationErrors.name ? "is-invalid" : ""}`}
            value={supplier.name}
            onChange={handleChange}
          />
           {validationErrors.name && (
            <span className="text-danger">{t('validateSuppliers.name')}</span>
          )}
        </div>

        {/* Nick Name */}
        <div className="form-group col-md-6">
          <label htmlFor="nickname">Nickname</label>
          <input
            type="text"
            id="nickname"
            name="nickname"
            className="form-control"
            value={supplier.nickname}
            onChange={handleChange}
          />
        </div>

        {/* Town */}
        <div className="form-group col-md-6">
          <label htmlFor="town">{t("customers.town")}</label>
          <span className="text-danger"> *</span>
          <input
            type="text"
            id="town"
            name="town"
            className={`form-control ${validationErrors.town ? "is-invalid" : ""}`}
            value={supplier.town}
            onChange={handleChange}
          />
          {validationErrors.town && <span className="text-danger">{t('validateSuppliers.town')}</span>}
        </div>

        {/* Phone Number */}
        <div className="form-group col-md-6">
          <label htmlFor="phoneNumber">{t("users.phone")}</label>
          <input
            type="text"
            id="phoneNumber"
            name="phoneNumber"
            className="form-control"
            value={supplier.phoneNumber}
            onChange={handleChange}
          />
        </div>

        {/* Email */}
        <div className="form-group col-md-6">
          <label htmlFor="email">{t("email.email")}</label>
          <input
            type="email"
            id="email"
            name="email"
            className="form-control"
            value={supplier.email}
            onChange={handleChange}
          />
        </div>

        {/* Vendor Code */}
        <div className="form-group col-md-6">
          <label htmlFor="vendorCode">Vendor Code</label>
          <input
            type="text"
            id="vendorCode"
            name="vendorCode"
            className="form-control"
            value={supplier.vendorCode}
            onChange={handleChange}
          />
        </div>

        {/* Country */}
        <div className="form-group col-md-6">
          <label htmlFor="country">{t("customers.country")}</label>
          <span className="text-danger"> *</span>
          <Select
            menuPlacement="auto"
            styles={{
              ...selectStyle,
              control: (styles) => ({
                ...styles,
                borderColor: validationErrors.country ? "#dc3545" : styles.borderColor,
              }),
            }}
            options={countries} // Assuming `countries` is an array of objects with `label` and `value` keys
            value={countries.find((country) => country.label === supplier.country)}
            isSearchable={true}
            onChange={(selectedOption) =>
              handleClientChange("country", selectedOption.label)
            }
            placeholder={t("invoice.select")}
            name="country"
            getOptionLabel={(option) => option.label}
            getOptionValue={(option) => option.value}
          />
          {validationErrors.country && (
            <span className="text-danger">{t('validateSuppliers.country')}</span>
          )}
        </div>

        {/* shorterCode */}
        {/* Country 2A */}
        <div className="form-group col-md-6">
          <label htmlFor="country2a">Country 2A</label>
          <Select
            menuPlacement="auto"
            styles={selectStyle}
            options={countries}
            value={countries.find(country => country.shorterCode === supplier.country2a)}
            isSearchable={true}
            onChange={(selectedOption) =>
              handleClientChange(
                "country2a",
                selectedOption.shorterCode
              )
            }
            placeholder={t("invoice.select")}
            name="country2a"
            getOptionLabel={(option) => option.shorterCode}
            getOptionValue={(option) => option.shorterCode}
          />
        </div>

        {/* Country 3A */}
        <div className="form-group col-md-6">
          <label htmlFor="country3a">Country 3A</label>
          <Select
            menuPlacement="auto"
            styles={selectStyle}
            options={countries}
            value={countries.find(country => country.shortCode === supplier.country3a)}
            isSearchable={true}
            onChange={(selectedOption) =>
              handleClientChange(
                "country3a",
                selectedOption.shortCode
              )
            }
            placeholder={t("invoice.select")}
            name="country3a"
            getOptionLabel={(option) => option.shortCode}
            getOptionValue={(option) => option.shortCode}
          />
        </div>

        {/* Purchase Unit */}
        <div className="form-group col-md-6">
          <label htmlFor="purchaseUnit">{t("purchases.purchaseUnit")}</label>
          <Select
            menuPlacement="auto"
            styles={selectStyle}
            options={tcrList}
            value={
              tcrList?.find(
                (purchaseUnit) =>
                  purchaseUnit?.value === supplier?.purchaseUnit
              ) ?? ""
            }
            isSearchable={true}
            onChange={(purchaseUnit) =>
              handleClientChange(
                "purchaseUnit",
                purchaseUnit.value
              )
            }
            placeholder={t("invoice.select")}
            name="purchaseUnit"
          />
        </div>


        {/* Address as a single row */}
        <div className="form-group col-md-12">
          <label htmlFor="address">{t("customers.address")}</label>
          <span className="text-danger"> *</span>
          <input
            type="text"
            id="address"
            name="address"
            className={`form-control ${validationErrors.address ? "is-invalid" : ""}`}
            value={supplier.address}
            onChange={handleChange}
          />
        {validationErrors.address && (
            <span className="text-danger">{t('validateSuppliers.address')}</span>
          )}
        </div>

      </div>
    </div>
  );


  const footer = (
    <div className='d-flex gap-2 mt-4'>
        {fromInvoice && <button className="btn btn-outline-primary mr-2 mt-3" onClick={() => handleSaveChanges({saveInInvocieOnly:true})} >
          {loading ? (
            <Loader size="xs" color="white" />
          ) : (
            <>
            <FontAwesomeIcon icon={faFileInvoice} className="mr-2" />
              {t("invoice.saveInIvoice")}
            </>
          )}
      </button>}
        <button className="btn btn-primary mr-2 mt-3" onClick={handleSaveChanges} >
          {loading ? (
            <Loader size="xs" color="white" />
          ) : (
            <>
            <FontAwesomeIcon icon={faFileInvoice} className="mr-2" />
            {fromInvoice?t("invoice.saveInSystem"):t("common.save")}
            </>
          )}
      </button>
    </div>
  )

  return (
    <div className=" p-3">
      {content}
      {footer}
    </div>
  )
}

export default EditAddSupplies