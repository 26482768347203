import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment';
import React from 'react'
import { useTranslation } from 'react-i18next';
import { useAppContext } from '../../AppContext';
import clsx from 'clsx';

const AlertCertificate = ({marginLeft=true}={}) => {
const { t ,i18n} = useTranslation("translations");
const language=i18n.language;

const {
  company:[fullCompany],
} = useAppContext();


  let expirationDate = fullCompany?.certificateExpirationDate

if(!expirationDate) return <></>
const today=moment()
const daysDifference=  moment(expirationDate).diff(today, "days")
  return (
    (daysDifference <= 10) && <div className={clsx("alert alert-danger mb-0 px-3 py-2",marginLeft && "ml-3")} role="alert">
    <FontAwesomeIcon icon={faInfoCircle} className="mr-2" />
    {daysDifference<=0?t("dashboard.certificateExpired"):language=="alb"?`Certifikata juaj skadon në ${daysDifference} ditë`: `Your Certificate Expires in ${daysDifference} days`}
    <button type="button" className="close ml-2 text-center" data-dismiss="alert" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
  </div>
  )
}

export default AlertCertificate